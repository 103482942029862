import Colors from '../../theme/Colors';
import { fontRegular } from '../../theme/theme';

const styles = {
  cssLabel: {
    '&$cssFocused': {
      color: 'rgba(36, 37, 61, 0.5)'
      // transform: 'translate(12px, -6px) scale(0.75)'
    },
    '&$labelError': {
      color: Colors.primaryOrange
    },
    '&$shrink': {
      // transform: 'translate(12px, -6px) scale(0.75)'
    }
    // left: 8,
  },
  cssOutlinedInput: {
    borderColor: Colors.primaryBlue,
    borderWidth: 1,
    padding: 0,
    '&$cssFocused $notchedOutline': {
      borderColor: Colors.primaryBlue,
      borderWidth: 1
    },
    '&:hover $notchedOutline': {
      borderColor: Colors.primaryBlue,
      borderWidth: 1
    },
    '&$outlineError $notchedOutline': {
      borderColor: Colors.primaryOrange
    },
    '&$outlineDisabled $notchedOutline': {
      backgroundColor: '#ebebeb',
      border: 'solid 1px #9e9e9e'
    },
    '&.Mui-disabled': {
      color: Colors.grey900
    }
  },
  formHelper: {
    position: 'absolute',
    bottom: 0,
    left: -8,
    whiteSpace: 'nowrap',
    color: Colors.grey500
  },
  formHelperError: {
    color: Colors.primaryOrange
  },
  cssFocused: {
    color: Colors.grey900
  },
  notchedOutline: {
    backgroundColor: Colors.white,
    borderColor: Colors.primaryBlue,
    // borderRadius: 30,
    borderRadius: 2
  },
  input: {
    paddingTop: 6,
    paddingBottom: 6,
    paddingLeft: 14,
    paddingRight: 10,
    fontWeight: fontRegular,
    zIndex: 2,
    textOverflow: 'ellipsis',
    '&::-webkit-calendar-picker-indicator': {
      filter: `invert(28%)sepia(100%) hue-rotate(190deg) saturate(500%)`
    },

  },
  shrink: {},
  outlineDisabled: {},
  outlineError: {
    marginBottom: 16
  },
  labelError: {}
  // root: {
  //   zIndex: 1
  // }
};

export default styles;
