import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { withRouter } from 'react-router';
import Tooltip from "@material-ui/core/Tooltip";
import ExitToApp from '@material-ui/icons/ExitToApp'
import { UserIconTop } from '../../widgets/user/user-icon-top';
import RecentPagesBtn from '../Buttons/RecentPagesBtn/RecentPagesBtn';
import { ssoBaseURL } from '../../constants/Endpoints';
import QuestionMark from '../../widgets/questionMark/questionMark';
import { styles } from "./styles";
import env from "../../config/env";

const TopNavNoUser = props => {
  const { classes, drawer, noAccess } = props;

  function logout() {
    window.location.href = `${ssoBaseURL}logout`;
  }

  return (
    <div className={`${classes.topNavContainer} ${(props.location.pathname === '/home' || noAccess)
      ? classes.whiteTextColor : (env !== "prod") ? classes.whiteTextColor : classes.blackTextColor} ${drawer ? classes.drawerMode : classes.topNavMode}`}
    >
      <UserIconTop {...props} />
      {noAccess ? null : (
        <>
          <RecentPagesBtn {...props} />
          <QuestionMark {...props} />
        </>
      )}
      <Tooltip arrow title="Sign Out">
        <ExitToApp
          aria-owns={open ? "mouse-over-popover" : undefined}
          style={{marginLeft: 4, cursor: "pointer"}}
          onClick={logout}
        />
      </Tooltip>
    </div>
  )
};

export default withRouter(withStyles(styles)(TopNavNoUser));
