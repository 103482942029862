import React from "react";
import "./home.css";
import HomeRoots from "./home-roots";
import BackgroundProcessingBar from "../../widgets/bars/BackgroundProcessingBar";

const Home = () => {

  return (
    <>
      <div className="home-wrapper">
        <HomeRoots />
        <BackgroundProcessingBar style={{bottom: 0}} />
      </div>
    </>
  );
}


export default Home;
