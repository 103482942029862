import filter from 'lodash/filter';
/*
* pass compartment Json
* get root compartments
*/
export default function getRootCompartments(compartmentJson) {

  let rootCompts = filter(compartmentJson, compartment => compartment && compartment.isRoot);

  rootCompts = rootCompts.map ( c => {

    if (!c.children) {
      return c;
    }

    c.childrenData = c.children.map(ch =>  {
      const el = filter(compartmentJson, compartment => compartment && compartment.id === ch)[0];
      if(el){
        el.parentId = c.id;
        return el;
      }
    }

    );

    return c;
  });

  return rootCompts;
}
