import { makeStyles } from '@material-ui/core';
import Colors from '../../theme/Colors';
import { fontMedium } from '../../theme/theme';

const styles = theme => ({
  dialog: {
    width: 464
  },
  title:{
    fontWeight: fontMedium
  },
})

export default makeStyles(styles);
