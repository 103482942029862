import React, { useState, useEffect, createContext, useContext, useMemo, useCallback } from 'react';
import SessionModal from '../../widgets/modal/SessionModal';

export const SessionContext = createContext({});

function SessionProvider(props) {
  const [persistenceStorage, setPersistenceStorage] = useState({});
  const [sessionTimeout, setSessionTimeout] = useState(null);
  const [sessionTime, setSessionTime] = useState(0);

  // cleanup timeout
  useEffect(() => {
    return () => {
      clearTimeout(sessionTimeout);
    }
  }, []);

  const setSession = useCallback((persistKey, persistValue) => {
    setPersistenceStorage((persistenceStorage) => {
      return {
        ...persistenceStorage,
        [persistKey]: persistValue
      }
    });
  }, [setPersistenceStorage]);

  const removeSession = useCallback((persistKey) => {
    setPersistenceStorage((persistenceStorage) => {
      return {
        ...persistenceStorage,
        [persistKey]: undefined
      }
    });
  }, [setPersistenceStorage]);

  function updateSessionTimeout(timeout) {
    setSessionTimeout((sessionTimeout) => {
      const date = Date.now()
      clearTimeout(sessionTimeout);
      return setTimeout(() => {
        setSessionTime(date+timeout);
      }, timeout)
    })
  }
  window.updateSessionTimeout = updateSessionTimeout

  function persistSession() {
    for (const persistKey in persistenceStorage) {
      const persistValue = persistenceStorage[persistKey];
      if (persistValue) {
        try {
          window.sessionStorage.setItem(persistKey, JSON.stringify(persistValue))
        } catch (e) {
          console.error('Could not persist', persistKey, persistValue)
        }
      }
    }
  }
  window.persistSession = persistSession;

  const sessionContext = useMemo(() => {
    return {
      setSession,
      removeSession
    }
  }, [setPersistenceStorage, persistenceStorage])


  const { children } = props;

  return (
    <>
      <SessionModal sessionTime={sessionTime} setSessionTime={setSessionTime} />
      <SessionContext.Provider
        value={sessionContext}
      >
        {children}
      </SessionContext.Provider>
    </>

  );
}

export default SessionProvider;
