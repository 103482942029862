import Box from "@material-ui/core/Box";
import color from "@material-ui/core/colors/amber";
import Typography from '@material-ui/core/Typography';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import React, { useContext } from "react";
import { useHistory } from 'react-router-dom';
import env from "../../config/env";
import UserComptContext from '../../setup/compartments/compartment.context';
import Colors from "../../theme/Colors";
import getCompartmentsByType from '../../Utils/compartments/getCompartmentsByType';
import getCompartmentTypes from '../../Utils/compartments/getCompartmentTypes';
import getUserName from '../../Utils/getUserName';
import { UserDetailsPopover } from './user-details-popover';

export const UserIconTop = props => {
  const { classes = {} } = props;
  const { user, simulatedUser, simulatingUser, compartments } = useContext(UserComptContext);
  const history = useHistory();

  const [anchorEl, setAnchorEl] = React.useState(null);

  const currentUser = simulatingUser ? simulatedUser : user;

  let userCompartment, childId;
  if (compartments && compartments.length > 0) {
    const userCompartments = getCompartmentsByType(compartments, getCompartmentTypes.USERS, true);
    if (userCompartments && userCompartments.length > 0 && userCompartments[0] && userCompartments[0].children && userCompartments[0].children[2]) {
      userCompartment = userCompartments[0];
      childId = userCompartments[0].children[2];
    }
  }

  function navigate() {
    history.push(`/${getCompartmentTypes.USERS}/page/${userCompartment.id}/${childId}`);
  }

  const getUserAvatarCredentials = (name) => {
    return (name.split(" ").length === 1)
      ? name.substr(0, 2).toUpperCase()
      : name.split(" ").map(p => {
        return p[0] && p[0].toUpperCase()
      }).join("");
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const welcomeLabelStyles = {
    color: (props.location.pathname === '/home') ? Colors.white : (env !== "prod") ? color.white : Colors.black,
    marginTop: 4,
    marginRight: 17
  };

  const userName = getUserName(currentUser);
  const welcomeLabel = (props.location.pathname === '/home') ? `Welcome, ${userName}` : getUserAvatarCredentials(userName)
  return (
    <Box style={{display: "flex", justifyContent: "center", alignItems: 'center', marginRight: 4}}>
      {
        (typeof currentUser.id !== "undefined" && currentUser.userInfo) ? (
          <Typography variant='inherit' style={welcomeLabelStyles}>
            {welcomeLabel}
          </Typography>
        ) : null
      }
      <div
        onMouseEnter={handleClick}
        onMouseLeave={handleClose}
      >
        <SupervisorAccountIcon
          style={{cursor: "pointer", position: 'relative', top: 4}}
          aria-owns={props.open ? "mouse-over-popover" : undefined}
          onClick={userCompartment && childId ? navigate : undefined}
          aria-haspopup="true"
        />
        <UserDetailsPopover
          classes={classes}
          anchorEl={anchorEl}
          handlePopoverClose={handleClose}
        />
      </div>
    </Box>
  );
};
