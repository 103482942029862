import gql from "graphql-tag";
// const campaignId = "e2ee9d7f-901a-4870-b861-d6797d3d4562";

const GET_ALL_COMPARTMENTS = gql`
mutation {
  compartments(input: $input) @rest(type : "compartments", path: "all") {
    payload
  }
}
`;

export const LOCAL_COMPARTMENT = gql`
        fragment CustomCompartment on Compartment {
          compartmentList
        }
      `;

export default GET_ALL_COMPARTMENTS

// export const GET_COMPARTMENTS = gql`
//   query compartmentList {
//     compartmentList @client {
//       items,
//       offset,
//       total
//     }
//   }
// `
