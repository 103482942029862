const Colors = {
  //Blue Steel Colors
  //Grey
  grey900: '#424242',
  grey500: '#616161',
  grey300: '#9e9e9e',
  grey200: '#bbbbbb',
  grey100: '#eeeeee',
  //Blue Grey
  blueGrey900: '#454e64',
  blueGrey500: '#545F7a',
  blueGrey300: '#8492a8',
  blueGrey100: '#f5f6f8',
  //Blue
  lowlightBlue: '#0039a9',
  primaryBlue: '#0071e9',
  highlightBlue: '#5fa7f2',
  subtleBlue: '#dbecff',
  logoBlue: '#0066a0',

  // backgroundGradientalBlue: "linear-gradient(90deg, rgba(0,113,233,1) 0%, rgba(0,212,255,1) 51%, rgba(0,113,233,1) 100%)",
  recentsBtnBackgroundBlue: "#2196F3",
  auditTrailsBoxBlue: "#e1f3fd",
  auditTrailsBoxHeaderBlue: "#1977d3",
  //Green
  lowlightGreen: '#33691e',
  primaryGreen: '#66bc15',
  highlightGreen: '#b4f27b',
  subtleGreen: '#eafcda',
  bookmarkGreen: '#00CC00',
  //Black
  black: '#000000',
  //White
  white: '#ffffff',
  //Yellow
  lowlightYellow: '#a36c05',
  primaryYellow: '#f8b02a',
  highlightYellow: '#ffd684',
  subtleYellow: '#feefd4',
  //Magenta
  lowlightMagenta: '#970c71',
  primaryMagenta: '#cd179b',
  highlightMagenta: '#f556ca',
  subtleMagenta: '#ffd8f4',
  auditTrailBtnBackgroundMagenta: "#dc004e",
  //Orange
  lowlightOrange: '#ab2703',
  primaryOrange: '#e74517',
  highlightOrange: '#f19737',
  subtleOrange: '#ffccbb',
  red: '#ff0000',
  borderColor: '#cccccc',
  backgroundColor: '#f5f6f8',
  errorWidget: '#ffdede',
  //DarkGreen
  primaryDarkGreen: '#1f7714',
  currentMenuLightYellow: '#befb32'
}

export default Colors;
