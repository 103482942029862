/*
  get the label for the step
*/
export default function getStepLabel(description, roleName){
  if(description) {
    if (typeof(description) === 'object') {
      if (description[roleName]) {
        return description[roleName];
      } else if (description['DEFAULT']) {
        return description['DEFAULT'];
      } else {
        return '';
      }
    }
    if (description.indexOf('|') === -1) {
      return description;
    }
    const roleLabels = description.split('|');
    if (roleLabels[0].indexOf('~') === -1) {
      return roleLabels[0];
    }
    const roleLabel = roleLabels[0].split('~').find((x)=> x.indexOf(roleName) !== -1);
    if (roleLabel) {
      return roleLabel.replace((roleName+ ':'), '');
    }
  }

  return '';
}
