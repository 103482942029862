import React from 'react';
import { Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import useStyles from './BackgroundProcessingBar.styles';
import { useProcessingContext } from '../../containers/processing/BackgroundProcessingProvider';

function BackgroundProcessingBar(props) {
  const { style } = props;
  const processes = useProcessingContext();
  const classes = useStyles();


  function renderBar({ message }) {
    return (
      <div className={classes.barContainer}>
        <div className={classes.leftContainer}>
          <Typography variant='body2' component='div' className={classes.text}>
            {message}
          </Typography>
        </div>
      </div>
    );
  }

  return (
    <div className={classes.root} style={style}>
      {processes.map(renderBar)}
    </div>
  );

}
BackgroundProcessingBar.defaultProps = {
  style: {bottom: 0}
};
BackgroundProcessingBar.propTypes = {
  style: PropTypes.object
}
export default BackgroundProcessingBar;
