export const checkNonZeroArray = (arr) => {
  return arr && Array.isArray(arr) && arr.length > 0;
}

export const getPropValueOrDefault = (obj, property) => {
  return obj[property] ? obj[property] : {}
}

export const initObject = (obj) => {
  return obj ? obj : {};
}

export const initNull = (obj) => {
  return obj ? obj : null;
}

export const initString = (prop) => {
  return prop ? prop : '';
}
// check whether undefined is present in the input values
export const checkForUndefined = (item) => {
  return Array.isArray(item) ? item.some(i => i && i.includes(undefined)) : item && item.includes(undefined)
}

// Initialise Object with empty value if prop doesnot exist
export const initObjProp = (obj, prop) => {
  if(!obj[prop]){
    obj[prop] = {};
  }
}

// Pick common keys between two Objects and return as Array of keys
export const intersection = (o1,o2, excludeKeys = []) => {
  return Object.keys(o1).filter(obj1Key => excludeKeys.indexOf(obj1Key) === -1).filter({}.hasOwnProperty.bind(o2));
}

export const checkForTruthy = (val) => {
  return val === 'true' || val === true
}
export const checkNumberOnly = (val) => {
  const allowNumbersOnly = /^\d+$/
  return allowNumbersOnly.test(val)
}

export const getFileNameAndExtension = (fileName) => {
  const period = fileName.lastIndexOf('.');

  let name, extension;
  if (period >= 0) {
    name = fileName.slice(0, period);
    extension = fileName.slice(period);
  } else {
    name = fileName;
    extension = '';
  }
  name = name && name.toUpperCase();
  return [name, extension];
}
