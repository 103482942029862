import React, { useMemo, useState, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Typography
} from '@material-ui/core';
import useStyles from './SessionModal.styles';
import CustomButtom from '../buttons/CustomButton/CustomButton';
import { ssoBaseURL } from '../../constants/Endpoints';
import { SESSION_WARNING_TIME } from '../../constants/Constants';
import useLoadingService from '../../hooks/useLoadingService';
import GetMyUserService from '../../services/user/GetMyUserService';

function calculateSessionWarningTime(sessionTime) {
  const warningTime = SESSION_WARNING_TIME * 1000;
  const timeDiff = Math.round(((sessionTime + warningTime) - Date.now()) / 1000);
  return timeDiff >= 0 ? timeDiff : 0
}

const SessionModal = ({ sessionTime, setSessionTime }) => {
  const classes = useStyles();
  const [timer, setTimer] = useState(SESSION_WARNING_TIME);
  const [timerInterval, setTimerInterval] = useState(null);

  // clean up interval
  useEffect(() => {
    return () => {
      clearInterval(timerInterval);
    }
  }, [])

  useEffect(() => {
    if (sessionTime) {
      setTimer(calculateSessionWarningTime(sessionTime));
      setTimerInterval((timerInterval) => {
        clearInterval(timerInterval);
        const interval = setInterval(() => {
          let newTimer = calculateSessionWarningTime(sessionTime);
          if (newTimer <= 0) {
            clearInterval(interval);
            newTimer = 0;
          }
          setTimer(newTimer)
        }, 1000);
        return interval;
      });
    } else {
      clearInterval(timerInterval);
      setTimerInterval(null);
    }
  }, [sessionTime])

  const [getUser, { loading }] = useLoadingService(GetMyUserService);

  const actions = useMemo(() => {
    async function refreshToken() {
      await getUser();
      setSessionTime(0);
    }
    if (timer || timer > 0) {
      return [
        {
          label: 'Logout',
          onClick: () => {
            window.location.href = `${ssoBaseURL}logout`
          },
          variant: 'outlined',
          disabled: loading
        },
        {
          label: 'Continue',
          onClick: refreshToken,
          variant: 'contained',
          disabled: loading
        },
      ]
    } else {
      return [
        {
          label: 'Login',
          onClick: refreshToken,
          variant: 'contained',
          disabled: loading
        },
      ]
    }
  }, [timer])


  let description, title
  if (timer || timer > 0) {
    description = 'Your session is about to expire due to inactivity. Would you like to continue?';
    title = `Session Inactivity: ${timerToText(timer)}`
  } else {
    title = 'Session Expired'
    description = 'Session has expired. You can attempt to restore your session by clicking Login.'
  }

  function timerToText(timer) {
    if (timer > 30) {
      const minutes = Math.ceil(timer / 60)
      return `${minutes} minute${minutes > 1 ? 's' : ''}`
    } else {
      return `${timer} second${timer > 1 ? 's' : ''}`;
    }
  }

  const renderButton = ({ label, onClick, variant }) => {
    return (
      <CustomButtom
        key={label}
        color='secondary'
        label={label}
        onClick={onClick}
        variant={variant}
      />
    );
  }

  return (
    <Dialog
      open={!!sessionTime}
      disableBackdropClick
      disableEscapeKeyDown
      classes={{
        paper: classes.dialog
      }}
    >
      <DialogTitle className={classes.title} disableTypography>
        <Typography variant='h4'>
          {title}
        </Typography>
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          {description}
        </DialogContentText>
      </DialogContent>
      {actions ? (
        <DialogActions>
          {actions.map(renderButton)}
        </DialogActions>
      ) : null}
    </Dialog>
  );
}

export default SessionModal;
