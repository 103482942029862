import { low } from '../../constants/Constants';

export default function getValueFromOptions(options, value, canEmpty) {
  if (options) {
    const option = options.filter((val) => low(val.value) === low(value))[0];
    return option ? option : canEmpty ? {} : options[0]
  } else {
    return {};
  }
}
