import useSetPersistence from './useSetPersistence';
import useGetPersistence from './useGetPersistence';

function usePersistence(persistKey, persistValue) {
  useSetPersistence(persistKey, persistValue);
  const data = useGetPersistence(persistKey);
  return data;
}

export default usePersistence;
