import React, { PureComponent } from 'react';
import { withStyles, CircularProgress, Typography } from '@material-ui/core';
import styles from './styles';

class ProgressCircle extends PureComponent {
  constructor(props) {
    super(props);
  }

  render() {
    const { classes, value, text, color, Icon, size, thickness } = this.props;
    return (
      <div className={classes.root}>
        <CircularProgress
          classes={{
            root: classes.circleBackgroundRoot,
            circle: classes.circleBackground,
            colorPrimary: classes.circleBackgroundColor,
            colorSecondary: classes.circleSecondaryBackgroundColor
          }}
          variant='static'
          value={100}
          size={size}
          color={color}
          thickness={thickness}
        />
        <CircularProgress
          classes={{
            root: classes.circleMainRoot,
            circle: classes.circleMain,
            colorPrimary: classes.circleMainColor,
            colorSecondary: classes.circleSecondaryMainColor,
            svg: classes.circleSvg
          }}
          variant='static'
          value={value}
          size={size}
          color={color}
          thickness={thickness}
        />
        <Typography variant='body2' className={classes.text}>{text}</Typography>
        { Icon &&
          <Icon className={classes.icon} />
        }
      </div>
    )
  }

}

ProgressCircle.defaultProps = {
  color: 'primary',
  size: 50,
  thickness: 5
}

export default withStyles(styles)(ProgressCircle);
