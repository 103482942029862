import { baseURL, baseHeaders } from '../../constants/Endpoints';
import InsightsKeys from './app-insights.key'

export default async function getInsightsEvents(userId){

  const headers = baseHeaders();

  const fetchedResource = await fetch(
    `${baseURL}recent/${InsightsKeys.APP_ID}/${userId}`,
    {
      method: "GET",
      headers: headers
    }
  );
  if (fetchedResource.ok) {
    const json = await fetchedResource.json();
    return json;
  }
}
