import React from 'react';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';

const useStyles = makeStyles({
  text: {
    fontSize: 18,
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.11,
    letterSpacing: 'normal',
    textAlign: 'center',
    color: '#424242'
  }
});

function TitleText(props) {
  const classes = useStyles();
  const {className, children, ...otherProps} = props;

  return <Typography {...otherProps} className={clsx(classes.text, className)}>{children}</Typography>
}

export default TitleText;
