import React, { useState, useEffect, createContext, useContext } from 'react';
import { isMobileOnly, isTablet, isBrowser } from 'react-device-detect';

export const ScreenContext = createContext({});
const useScreen = () => useContext(ScreenContext);

export function ScreenProvider({children}) {
  const [width, setWidth] = useState(window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth)
  const [height, setHeight] = useState(window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight)
  const [isLandscape, setIsLandscape] = useState(width >= height);

  useEffect(() => {
    function handleResize() {
      const width = window.innerWidth
        || document.documentElement.clientWidth
        || document.body.clientWidth;

      const height = window.innerHeight
        || document.documentElement.clientHeight
        || document.body.clientHeight;

      setWidth(width);
      setHeight(height);
      setIsLandscape(width >= height);
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <ScreenContext.Provider
      value={{
        isLandscape,
        isPortrait: !isLandscape,
        isMobile: isMobileOnly,
        isTablet,
        isBrowser,
        width,
        height
      }}
    >
      {children}
    </ScreenContext.Provider>
  );
}

export default useScreen;
