import Colors from '../../theme/Colors';
import { fontRegular } from '../../theme/theme';

const styles = theme => ({
  root: {
    flexGrow: 1,
    height: 250
  },
  input: {
    display: 'flex',
    paddingTop: 9,
    paddingBottom: 12,
    paddingLeft: 14,
    paddingRight: 12
  },
  inputContainer: {
    width: '100%'
  },
  selectContainer: {
    width: '100%'
  },
  valueContainer: {
    display: 'flex',
    flex: 1,
    alignItems: 'center',
    width: '80%'
    // overflow: 'hidden'
  },
  container: {
    width: '100%'
  },
  noOptionsMessage: {
    padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`
  },
  singleValue: {
    fontWeight: fontRegular
  },
  placeholder: {
    position: 'absolute',
    left: 16,
    color: Colors.grey300,
    fontWeight: fontRegular
  },
  paper: {
    position: 'absolute',
    zIndex: 10,
    width: '100%',
    boxSizing: 'border-box'
  },
  divider: {
    height: theme.spacing(2)
  }
});

export default styles;
