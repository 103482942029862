import React from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";

import CardBodyStyles from "./card-body.styles";

function CardBody({ ...props }) {
  const { classes, className, children, plain, normal, ...rest } = props;
  const cardBodyClasses = clsx({
    [classes.cardBody]: true,
    [classes.cardBodyNormal]: normal,
    [className]: className !== undefined
  });
  return (
    <div className={cardBodyClasses} {...rest}>
      {children}
    </div>
  );
}

CardBody.propTypes = {
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
  normal: PropTypes.bool
};

export default withStyles(CardBodyStyles)(CardBody);
