import { baseFolder, baseFolderDownload } from '../../constants/Endpoints';

function concatList(blob, blobList, isDownload) {
  const path = isDownload ? baseFolderDownload : baseFolder;
  if (!blobList[path]) {
    blobList[path] = [blob];
  } else if (blobList[path] && blobList[path].findIndex((_blob) => _blob.name === blob.name) < 0) {
    blobList[path].push(blob);
  }
}

export default concatList
