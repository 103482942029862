import React, { useContext } from "react";
import { Grid } from "@material-ui/core";
import isEmpty from "lodash/isEmpty";
import "./home.css";
import getRootCompartments from "../../Utils/compartments/getRootCompartments";
import { Labels } from '../../constants/labels';
import { useAppInsightsContext } from '../../setup/application-insights/app-insights.context';
import useCustomRenderEvent from '../../setup/application-insights/track-render-event.hook';
import UserComptContext from '../../setup/compartments/compartment.context';
import getSkeleton from '../../Utils/skeleton/getSkeleton';
import NaviButton from "../templates/NaviButton";

const HomeRoots = (props) => {
  // insights - home screen
  const reactPlugin = useAppInsightsContext()
  useCustomRenderEvent(reactPlugin, Labels.HOME_SCREEN , props)

  // get all compartments
  const allCompartments = useContext(UserComptContext);

  // get root compartments
  let rootCompts = [];
  if (allCompartments) {
    rootCompts = getRootCompartments(allCompartments.compartments);
  }

  return (
    <div className='home-grid-wrapper' style={{ position: 'relative' }}>
      <Grid
        container
        className="home-container"
        spacing={2}
        style={{ margin: 'auto', width: '100%' }}
      >
        {!isEmpty(rootCompts)
          ? <NaviButton data={rootCompts} /> : getSkeleton(4)}
      </Grid>
    </div>
  );
}


export default HomeRoots;
