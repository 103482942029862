import { makeStyles } from '@material-ui/core';
import Colors from '../../theme/Colors';

const styles = theme => ({
  dialog: {
    width: '50%',
    backgroundColor: Colors.primaryBlue
  },
  dialogConfirmation: {
    width: '50%'
  },
  workflowEndContainer: {
    display: 'flex',
    height: '100%',
    width: '100%',
    flexDirection: 'column'
  },
  headerContainer: {
    padding: '8px 16px',
    '& p': {
      color: Colors.white
    }
  },
  contentContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: Colors.white,
    padding: 32,
    '& > *:not(:last-child)': {
      marginBottom: 16
    },
    '& p > span': {
      color: Colors.primaryBlue
    }
  },
  buttonWrapper: {
    marginTop: 16
  },
  button: {
    width: 150
  },
  checkIcon: {
    width: 64,
    height: 64,
    color: Colors.primaryGreen
  },
  info:{
    display: 'flex',
    flexDirection: 'row',
    padding: '6px 0px',
  },
  infoIcon:{
    padding:"0px 3px 0px 0px",
  },
  customTitle:{
    padding:"12px 24px",
  },
  iText:{
    fontSize:'0.8rem'
  },
  buttonBorder: {
    borderTop: "1px solid rgba(224, 224, 224, 1)"
  }
})

export default makeStyles(styles);
