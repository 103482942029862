import React from 'react';
import { Paper, Grid, Typography, Button, Divider } from '@material-ui/core';
import clsx from 'clsx';
import Info from '@material-ui/icons/Info'
import CheckCircle from '@material-ui/icons/CheckCircle'
import Warning from '@material-ui/icons/Warning'
import useStyles from './Notification.styles';

function Notification(props, ref) {
  const { title, message, actions, variant } = props;
  const classes = useStyles();

  let Icon;
  if (variant === 'error') {
    Icon = Warning;
  } else if (variant === 'success') {
    Icon = CheckCircle
  } else {
    Icon = Info;
  }

  return (
    <div ref={ref} className={classes.root}>
      <Paper elevation={3} className={classes.paper}>
        <Grid container wrap='nowrap'>
          <Grid item className={classes.iconContainer}>
            <Icon className={clsx(classes.icon, { [classes.iconError]: variant === 'error', [classes.iconSuccess]: variant === 'success' })} />
          </Grid>
          <Grid className={classes.messageContainer}>
            <Typography variant='body2' component='div' className={classes.title}>
              {title}
            </Typography>
            <Typography
              variant='body2'
              component='div'
              className={clsx({
                [classes.message]: message && (message.split(' ').length === 1),
                [classes.scrollOverflow]: !(message && (message.split(' ').length === 1))
              })}
            >
              {message}
            </Typography>
          </Grid>
        </Grid>
        <Grid container justify="flex-end">
          {actions.map((action,index) => (
            <Grid item key={index} className={classes.buttonContainer}>
              <Button
                onClick={action.onClick}
                size='small'
                color="secondary"
                className={classes.button}
                classes={{
                  // label: clsx(classes.buttonLabel, { [classes.buttonLabelError]: variant === 'error', [classes.buttonLabelSuccess]: variant === 'success' })
                }}
              >
                {action.label}
              </Button>
            </Grid>
          ))}
        </Grid>
      </Paper>
      <Divider />
    </div>
  );

}




export default React.forwardRef(Notification);
