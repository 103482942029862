import { makeStyles } from '@material-ui/core/styles';
import Colors from '../../theme/Colors'

const styles = () => ({
  root: {
    background: Colors.backgroundColor,
    position: 'relative',
    height: '100%',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    boxSizing: 'border-box'
  },
  card: {
    borderRadius: 8,
    // backgroundColor: Colors.white,
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    marginTop: -64
  },
  messageContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    padding: 8
  },
  button: {
    margin: 8,
    marginTop: 32,
    marginLeft: 'auto',
    marginRight: 'auto'
  },
  text: {
    textAlign: 'center',
    width: '100%',
    paddingTop: 8
  },
  lightText: {
    color: Colors.grey300,
    fontSize: 14,
    textAlign: 'center',
    width: '100%'
  }
});

export default makeStyles(styles);
