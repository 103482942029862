
import React from 'react';
import { MuiThemeProvider } from '@material-ui/core/styles';
import {
  MuiPickersUtilsProvider
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { SnackbarProvider } from 'notistack';
import { BrowserRouter } from 'react-router-dom';
import "./App.css";
import theme from './theme/theme';
import FileTracker from './containers/biglist/FileTracker/FileTracker';
import Routes from './Routes';
import { ScreenProvider } from './hooks/useScreen';
import UserComptContextProvider from './setup/compartments/compartment.provider';
import BrandingProvider from './containers/branding/BrandingProvider';
import SessionWrapper from './containers/persistence/SessionWrapper';
import AppLoading from './containers/loading/AppLoading';
import BackgroundProcessingProvider from './containers/processing/BackgroundProcessingProvider';
import ErrorBoundary from './Components/ErrorBoundary/ErrorBoundary';
import { ItemContextProvider } from './compartments/item/ItemContextProvider';

const App = () => {
  return (
    <BrowserRouter>
      <ErrorBoundary>
        <MuiThemeProvider theme={theme}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <UserComptContextProvider>
              <SnackbarProvider maxSnack={4} classes={{ containerAnchorOriginTopRight: 'snackbar-top' }}>
                <ScreenProvider>
                  <BackgroundProcessingProvider>
                    <FileTracker>
                      <BrandingProvider>
                        <ItemContextProvider>
                          <AppLoading>
                            <SessionWrapper>
                              <Routes />
                            </SessionWrapper>
                          </AppLoading>
                        </ItemContextProvider>
                      </BrandingProvider>
                    </FileTracker>
                  </BackgroundProcessingProvider>
                </ScreenProvider>
              </SnackbarProvider>
            </UserComptContextProvider>
          </MuiPickersUtilsProvider>
        </MuiThemeProvider>
      </ErrorBoundary>
    </BrowserRouter>
  );
}

export default App;

