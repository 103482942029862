import React from 'react';
import { withStyles } from '@material-ui/core'
import styles from './background.styles';


function Background(props) {
  const { classes, children } = props;
  return (
    <div className={classes.background}>
      {children}
    </div>
  )
}




export default withStyles(styles)(Background);
