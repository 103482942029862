import React, { useContext } from "react";
import { makeStyles } from '@material-ui/core/styles';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import getRootCompartments from "../../Utils/compartments/getRootCompartments";
import { Labels } from '../../constants/labels';
import { useAppInsightsContext } from '../../setup/application-insights/app-insights.context';
import useCustomRenderEvent from '../../setup/application-insights/track-render-event.hook';
import UserComptContext from '../../setup/compartments/compartment.context';


const useStyles = makeStyles(theme => ({
  root: {
    marginLeft: "auto",
    marginRight: "auto",
    marginTop: "20px"
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  }
}));

const qna = [
  {category: 0, question: "How to quickly access last viewed campaigns?", answer: "Click the button on the top right of the page, and you will see the history."},
  {category: 0, question: "How to view all ongoing campaigns?", answer: "On the navigation panel, click View button at the Campaigns block"},
  {category: 1, question: "How to quickly access last viewed coupons?", answer: "Click the button on the top right of the page, and you will see the history."},
  {category: 1, question: "How to view all ongoing coupons?", answer: "On the navigation panel, click View button at the Coupons block"}
];


export default function FAQComponent(props) {
  const classes = useStyles();

  const [selectedCategoryIndex, setSelectedCategoryIndex] = React.useState(0);

  // insights - home screen
  const reactPlugin = useAppInsightsContext()
  useCustomRenderEvent(reactPlugin, Labels.HOME_SCREEN , props)

  // get all compartments
  const allCompartments = useContext(UserComptContext);

  // get root compartments
  let rootCompts = [];
  const categories = [];
  if (allCompartments) {
    rootCompts = getRootCompartments(allCompartments.compartments);
    rootCompts.forEach(rc => {
      categories.push(rc.name);
    })
  }

  return (

    <Container className={classes.root}>
      <Grid container alignItems="center">
        <Grid item xs={4}>
          <h2>Frequently Asked Questions</h2>
        </Grid>
        <Grid item xs={8} style={{textAlign: "right"}}>
          <Tabs
            value={selectedCategoryIndex}
            onChange={(event, newValue) => setSelectedCategoryIndex(newValue)}
            indicatorColor="primary"
            textColor="primary"
            variant="scrollable"
          >
            {
              categories.map ((c, index) => {
                return (
                  <Tab label={c} key={"FAQTab" + index} />
                )
              })
            }
          </Tabs>
        </Grid>
      </Grid>
      {
        qna.filter((t,index) => {
          return (t.category === selectedCategoryIndex)
        }).map( (q, index) => (
          <ExpansionPanel
            defaultExpanded={index===0}
            key={"FAQExPanel" + index}
          >
            <ExpansionPanelSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
            >
              <Typography className={classes.heading}>{q.question}</Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <Typography>
                {q.answer}
              </Typography>
            </ExpansionPanelDetails>
          </ExpansionPanel>
        )
        )
      }
    </Container>
  )

}
