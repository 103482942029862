
import React from 'react';
import Select from 'react-select';
import Skeleton from 'react-loading-skeleton';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import MenuItem from '@material-ui/core/MenuItem';
import TextInput from './TextInput';
import styles from './SuggestInputStyles';
import Colors from '../../theme/Colors';
import getValueFromOptions from '../../Utils/select/getValueFromOptions';

export function NoOptionsMessage(props) {
  return (
    <Typography
      color="textSecondary"
      className={props.selectProps.classes.noOptionsMessage}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  );
}

export function inputComponent({ inputRef, ...props }) {
  return <div ref={inputRef} {...props} />;
}

export function Control(props) {
  return (
    <TextInput
      className={props.selectProps.classes.inputContainer}
      InputLabelProps={props.selectProps.InputLabelProps ? props.selectProps.InputLabelProps : {
        shrink: props.selectProps.placeholder ? true : props.hasValue ? props.hasValue : undefined
      }}
      disabled={props.selectProps.isDisabled}
      InputProps={{
        inputComponent,
        inputProps: {
          className: props.selectProps.classes.input,
          inputRef: props.innerRef,
          children: props.children,
          ...props.innerProps
        }
      }}
      label={props.selectProps.label}
      {...props.selectProps.textFieldProps}
      margin={props.selectProps.margin}
      onChange={props.selectProps.allowTextOnly ? event => props.selectProps.onChange(event.target) : null}
    />
  );
}

export function Option(props) {
  return (
    <MenuItem
      buttonRef={props.innerRef}
      selected={props.isFocused}
      component="div"
      style={{
        // fontWeight: props.isSelected ? 500 : 400
        backgroundColor: props.isSelected ? Colors.subtleBlue : undefined,
        color: props.isSelected ? Colors.primaryBlue : Colors.grey900,
        textOverflow: 'ellipse',
        height: 48
      }}
      {...props.innerProps}
    >
      <Typography noWrap>
        {props.children}
      </Typography>
    </MenuItem>
  );
}

export function Placeholder(props) {
  return (
    <Typography
      color="textSecondary"
      className={props.selectProps.classes.placeholder}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  );
}

export function SingleValue(props) {
  return (
    <Typography className={props.selectProps.classes.singleValue} {...props.innerProps} noWrap>
      {props.children}
    </Typography>
  );
}

export function ValueContainer(props) {
  return <div className={props.selectProps.classes.valueContainer}>{props.children}</div>;
}

export function Menu(props) {
  return (
    <Paper
      ref={props.innerRef}
      square
      className={props.selectProps.classes.paper}
      style={{
        bottom: props.placement === 'top' ? '100%' : null,
        top: props.placement === 'top' ? null : '100%'
        // maxHeight: props.maxHeight ? props.maxHeight : 286
      }}
      {...props.innerProps}
    >
      {props.children}
    </Paper>
  );
}

export function LoadingMessage() {
  return <Skeleton height={48} />
}

const components = {
  Control,
  Menu: Menu,
  NoOptionsMessage,
  Option,
  Placeholder,
  SingleValue,
  ValueContainer,
  LoadingMessage
};

class SuggestInput extends React.Component {
  constructor(props) {
    super(props);

    this.handleOnChange = this.handleOnChange.bind(this);
  }

  handleOnChange(event) {
    const value = event ? event.value : null;
    this.props.onChange({ target: { value: value } })
  }

  render() {
    const {
      classes,
      options,
      value,
      label,
      className,
      margin,
      InputLabelProps,
      placeholder,
      style,
      disabled,
      InputProps,
      noOptionsMessage,
      allowTextOnly,
      ...otherProps
    } = this.props;

    const selectStyles = {
      input: base => ({
        ...base,
        '& input': {
          font: 'inherit'
        }
      })
    };

    const valueFromOptions = getValueFromOptions(options, value, true);
    const selectValue = Object.keys(valueFromOptions).length
      ? valueFromOptions
      : allowTextOnly
        ? { value: value, label: value }
        : {};

    return (
      <div className={className} style={style}>
        <Select
          {...otherProps}
          allowTextOnly={allowTextOnly}
          InputProps={InputProps}
          noOptionsMessage={noOptionsMessage}
          isDisabled={disabled}
          className={classes.container}
          classes={classes}
          styles={selectStyles}
          options={options}
          InputLabelProps={InputLabelProps}
          components={{
            ...components,
            ...(InputProps && InputProps.endAdornment ? { IndicatorsContainer: () => InputProps.endAdornment } : {})
          }}
          value={selectValue}
          onChange={this.handleOnChange}
          placeholder={placeholder ? placeholder : ''}
          label={label}
          isClearable
          margin={margin}
          menuPlacement='auto'
          maxMenuHeight={286}
          theme={{
            borderRadius: 4,
            "colors": {
              "primary": "#2684FF",
              "primary75": "#4C9AFF",
              "primary50": "#B2D4FF",
              "primary25": "#DEEBFF",
              "danger": "#DE350B",
              "dangerLight": "#FFBDAD",
              "neutral0": "hsl(0, 0%, 100%)",
              "neutral5": "hsl(0, 0%, 95%)",
              "neutral10": "hsl(0, 0%, 90%)",
              "neutral20": "hsl(0, 0%, 80%)",
              "neutral30": "hsl(0, 0%, 70%)",
              "neutral40": "hsl(0, 0%, 60%)",
              "neutral50": "hsl(0, 0%, 50%)",
              "neutral60": "hsl(0, 0%, 40%)",
              "neutral70": "hsl(0, 0%, 30%)",
              "neutral80": "hsl(0, 0%, 20%)",
              "neutral90": "hsl(0, 0%, 10%)"
            },
            spacing: {
              baseUnit: 1,
              controlHeight: 0,
              menuGutter: 0
            }
          }}
        />
      </div>
    );
  }
}

SuggestInput.defaultProps = {
  width: '100%'
}

export default withStyles(styles, { withTheme: true })(SuggestInput);
