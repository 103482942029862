import filter from 'lodash/filter';

/**
 * takes compartment object, compartmentid
 * returns compartment json
 **/
export default function getCompartmentbyId(compartmentJson, compartmentId) {
  let compartment = {};
  compartment = filter(compartmentJson, co => co && co.compartmentId === compartmentId)[0];
  return compartment;
}
