import getCompartmentTypes from '../compartments/getCompartmentTypes'
import { SearchTypes, low } from '../../constants/Constants';
import { Index } from '../../constants/Endpoints';

function getIndexByType(type) {
  let index;

  switch (low(type)) {
    case low(getCompartmentTypes.CAMPAIGN):
      index = Index.CAMPAIGN;
      break;
    case low(getCompartmentTypes.PROMOTION):
      index = Index.PROMOTION;
      break;
    case low(getCompartmentTypes.COUPON):
    case low(getCompartmentTypes.COUPONGROUPS):
      index = Index.COUPON;
      break;
    case low(SearchTypes.ITEM):
      index = Index.ITEMS;
      break;
    case low(SearchTypes.USERS):
      index = Index.USER;
      break;
    case low(SearchTypes.CLUB):
      index = Index.CLUB;
      break;
    case low(SearchTypes.COUPON):
      index = Index.COUPON;
      break;
    case low(SearchTypes.ACTION):
      index = Index.ACTION;
      break;
    case low(SearchTypes.COUPONCODE):
      index = Index.COUPONCODE;
      break;
    case low(SearchTypes.OFFERSET):
      index = Index.OFFERSET;
      break;
    case low(SearchTypes.TERMSANDCONDITIONS):
      index = Index.TERMSANDCONDITIONS;
      break;
    case low(SearchTypes.LABEL):
      index = Index.LABEL;
      break;
    case low(SearchTypes.EMAILTEMPLATE):
      index = Index.EMAILTEMPLATE;
      break;
    case low(SearchTypes.PROMOTIONGROUPS):
      index = Index.PROMOTIONGROUPS;
      break;
    case low(SearchTypes.GENERICTARGETEDMESSAGE):
      index = Index.GTMSLOT;
      break;
    case low(SearchTypes.GENERICTARGETEDMESSAGEBYSLOT):
      index = Index.GENERICTARGETEDMESSAGE;
      break;
    default:
      index = '';
      break;
  }

  return index;
}

export default getIndexByType;
