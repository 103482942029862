import React from 'react';
import { Button, Card, Typography, LinearProgress } from '@material-ui/core';
import InsertDriveFileOutlined from '@material-ui/icons/InsertDriveFileOutlined'
import Cancel from '@material-ui/icons/Cancel'
import CheckCircle from '@material-ui/icons/CheckCircle'
import { Status } from '../../../constants/Constants';
import useStyles from './File.styles';
import convertBytes from '../../../Utils/biglist/convertBytes';
import ProgressCircle from '../../Progress/ProgressCircle';

function File(props) {
  const classes = useStyles();
  const { fileObj, removeFile, variant, isDownload } = props;
  const { total, loaded, status, speed, name } = fileObj;
  const percent = loaded / total * 100;

  let estimatedCompleted, timeLeft;
  if (speed && status === Status.LOADING) {
    const estimatedTimeLeft = (total - loaded) / speed;
    estimatedCompleted = new Date(Date.now() + estimatedTimeLeft);
    const seconds = (estimatedTimeLeft / 1000).toFixed(0);
    const minutes = (seconds / 60).toFixed(1);
    const hours = (minutes / 60).toFixed(1);
    if (hours >= 1) {
      timeLeft = hours + ' hr'
    } else if (minutes >= 1) {
      timeLeft = minutes + ' min'
    } else {
      timeLeft = seconds + ' sec'
    }
  }

  function renderFileName() {
    return (
      <div className={variant === 'linear' ? classes.fileContainer : classes.fileContainerCircular}>
        <InsertDriveFileOutlined className={classes.fileIcon} />
        <div className={variant === 'linear' ? classes.fileName : classes.fileNameCircular}>
          <Typography noWrap>{name}</Typography>
          {isDownload ? null : <Typography>{convertBytes(total)}</Typography>}
        </div>
      </div>
    )
  }

  return (
    <div className={classes.root}>
      <Card className={variant === 'linear' ? classes.card : classes.cardCircular}>
        <div className={variant === 'linear' ? classes.leftContainer : classes.container}>
          {variant === 'linear' ? (
            <>
              {renderFileName()}
              <div className={classes.progressContainer}>
                <div className={classes.progressText}>
                  {(!isDownload && status === Status.LOADING) && (
                    <>
                      <Typography noWrap className={classes.text}>{percent.toFixed(0) + '%'}</Typography>
                      <Typography noWrap className={classes.text}>{convertBytes(speed * 1000, 1) + '/s'}</Typography>
                    </>
                  )}
                </div>
                <div className={classes.progressBarContainer}>
                  <LinearProgress
                    variant='determinate'
                    value={percent}
                    classes={{ root: classes.progressRoot, bar: classes.progressBar }}
                  />
                </div>
                <div className={classes.progressText}>
                  {status === Status.LOADING && (
                    <>
                      <Typography noWrap className={classes.text}>{estimatedCompleted ? estimatedCompleted.toLocaleTimeString() : null}</Typography>
                      <Typography noWrap className={classes.text}>{timeLeft}</Typography>
                    </>
                  )}
                </div>
              </div>
            </>
          ) : (
            <>
              <div className={classes.topContainer}>
                {renderFileName()}
                <div className={classes.progressContainerCircular}>
                  <div className={classes.progressBarContainer}>
                    <ProgressCircle
                      value={percent}
                      text={percent.toFixed(0) + '%'}
                    />
                  </div>
                </div>
              </div>
              <div className={classes.progressTextCircular}>
                {(status === Status.LOADING && speed) ? (
                  <>
                    <Typography style={{visibility: isDownload ? 'hidden' : 'inherit'}} className={classes.speedText}>{convertBytes(speed * 1000, 1) + '/s'}</Typography>
                    <Typography className={classes.text}>{timeLeft}</Typography>
                  </>
                ) : null}
              </div>
            </>
          )}
        </div>
        <Button onClick={() => removeFile(fileObj)} component="div" className={classes.buttonContainer}>
          {status === Status.COMPLETE ?
            <CheckCircle className={classes.checkCircleIcon} />
            : status === Status.LOADING ?
              <Cancel className={classes.cancelIconLoading} />
              :
              <Cancel className={classes.cancelIcon} />
          }
        </Button>
      </Card>
    </div>
  )
}

File.defaultProps = {
  variant: 'linear'
}

export default File;
