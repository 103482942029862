import React from 'react';
import { Typography } from '@material-ui/core';
import Info from '@material-ui/icons/Info'
import CheckCircle from '@material-ui/icons/CheckCircle'
import Warning from '@material-ui/icons/Warning'
import PropTypes from 'prop-types';
import useStyles from './SimulateUserNotification.styles';
import CustomButton from '../buttons/CustomButton/CustomButton';

function SimulateUserNotification(props) {
  const { onClick, variant, user } = props;
  const classes = useStyles();

  let Icon;
  if (variant === 'error') {
    Icon = Warning;
  } else if (variant === 'success') {
    Icon = CheckCircle
  } else {
    Icon = Info;
  }

  return (
    <div className={classes.root}>
      <div className={classes.leftContainer}>
        <Icon className={classes.icon} />
        <Typography variant='body2' component='div' className={classes.text}>
          {`Simulating User ${user && user.userId} - ${user && user.userInfo && user.userInfo.userName}`}
        </Typography>
      </div>
      <div className={classes.rightContainer}>
        <CustomButton
          onClick={onClick}
          size='small'
          color="secondary"
          className={classes.button}
          classes={{
          }}
        >
          Stop
        </CustomButton>
      </div>

    </div>
  );

}
SimulateUserNotification.propTypes = {
  user: PropTypes.object.isRequired
}

export default SimulateUserNotification;
