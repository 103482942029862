import React from 'react';
import TextField from '@material-ui/core/TextField';
import { withStyles } from '@material-ui/core/styles';
import { Height } from '@material-ui/icons';
import styles from './TextInputStyles';
import SuggestInput from './SuggestInput';

function TextInput(props) {
  const { classes, textFieldClasses, InputProps, inputComponent, inputRef, autocomplete, inputProps, error, maxLength, options, fullWidth, ...otherProps } = props;

  if (options) {
    return (
      <SuggestInput
        {...props}
        options={options}
        allowTextOnly
      />
    );
  }

  return (
    <TextField
      {...otherProps}
      classes={textFieldClasses}
      fullWidth={fullWidth}
      id={props.id}
      InputLabelProps={{
        ...props.InputLabelProps,
        shrink: true,
        classes: {
          shrink: classes.shrink,
          root: classes.cssLabel,
          focused: classes.cssFocused,
          error: classes.labelError
        }
      }}
      InputProps={{
        ...props.InputProps,
        classes: {
          root: classes.cssOutlinedInput,
          focused: classes.cssFocused,
          notchedOutline: classes.notchedOutline,
          input: classes.input,
          disabled: classes.outlineDisabled,
          error: classes.outlineError
        },
        endAdornment: InputProps && InputProps.endAdornment ? InputProps.endAdornment : undefined
      }}
      inputProps={{
        ...inputProps,
        maxLength: maxLength,
        autoComplete: autocomplete ? autocomplete : 'off'
      }}
      FormHelperTextProps={{
        ...props.FormHelperTextProps,
        classes: {
          root: classes.formHelper,
          error: classes.formHelperError
        }
      }}
      variant='outlined'
      helperText={error}
      error={!!error}
    />
  );
}

export default withStyles(styles, { withTheme: true })(TextInput);
