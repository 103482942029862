import filter from 'lodash/filter';
import toLower from 'lodash/toLower';
/**
 * takes children compartments, type to filter on
 * returns list of child compartments of that type
 **/
export default function getCompartmentsByType(compartmentJson, filterType, root) {
  const resultSet = filter(compartmentJson, compartment => toLower(compartment.type) === toLower(filterType) && (root ? compartment.isRoot : true));
  return resultSet;
}
