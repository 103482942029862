import { useMemo } from 'react';

function useGetPersistence(persistKey) {
  // We want to return session data only on first mount
  const data = useMemo(() => {
    try {
      const sessionValue = JSON.parse(window.sessionStorage.getItem(persistKey));
      if (sessionValue) {
        window.sessionStorage.removeItem(persistKey)
      }
      return sessionValue;
    } catch (e) {
      console.error('Could not retrieve persisted value', persistKey)
    }
    return null;
  }, []);

  return data;
}

export default useGetPersistence;
