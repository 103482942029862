import React from 'react'
import {
  SeverityLevel
} from '@microsoft/applicationinsights-web'

import { withRouter } from 'react-router-dom';
import { Grid, Button, withStyles, Typography } from "@material-ui/core";
import { appInsights } from '../../setup/application-insights/app-insights.service';
import styles from './styles';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props)
  }

  state = { hasError: false }

  componentDidMount() {
    this.unlisten = this.props.history.listen((location, action) => {
      if (this.state.hasError) {
        this.setState({ hasError: false });
      }
    });
  }

  componentWillUnmount() {
    this.unlisten();
  }



  onError() {
    const { classes } = this.props;
    return (
      <Grid
        className={classes.errorWrapper}
        container
        alignContent="center"
        alignItems="center"
        direction="column"
      >
        <Grid
          className={classes.errorContainer}
          container
          alignContent="center"
          alignItems="center"
          direction="column"
          justify="center"
          spacing={2}
        >
          <Typography variant="h5">
            something went wrong. Please try again later
          </Typography>
          <Button
            variant="outlined"
            color="primary"
            onClick={() => this.props.history.replace('/home')}
            className={classes.backToHomeButton}
          >
            Back to Home
          </Button>
        </Grid>
      </Grid>
    )

  }
  componentDidCatch(error, errorInfo) {
    this.setState({ hasError: true })

    appInsights.trackException({
      error,
      exception: error,
      severityLevel: SeverityLevel && SeverityLevel.Error,
      properties: { ...errorInfo }
    })
  }

  render() {
    const { hasError } = this.state
    const { children } = this.props
    if (hasError) {
      return this.onError()
    }

    return children
  }
}

export default withStyles(styles)(withRouter(ErrorBoundary))
