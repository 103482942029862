import { useState } from 'react';

import { BlobProcessStatus, BlobTypes, low } from '../../constants/Constants';
import concatList from '../../Utils/biglist/concatList';
import dateFormat from '../../Utils/date/dateFormat';
import useLoadingService from '../useLoadingService';
import getAllBigListService from '../../services/biglist/GetAllBiglistService';

/**
 *
 * @param {string} baseFolder - basePath where list will be uploaded in cloud.
 * @param {object} user - Logged in user object
 * @param {boolean} isDownload
 * @return {object} - {
 *  @type {function} getAllBigList - function to fetch metadata of all biglists from BE.,
 *  @type {object} blobList - an oject that contains biglist/ or download/ as keys. {
      @type {array} biglist/: all biglist metadata, @example "biglist/": [
              {
                "name":"88163MEMBERBIGLIST",
                "owners":[{"id":"e01836f9-77a2-4610-8468-5cb4421e1985","name":"SAMSUNG","vendorId":"12345"},{"id":"74ac08a2-7690-43a8-bf0b-fc0a5f329b3b","name":"MARS WRIGLEY CONFECTIONERY US, LLC","vendorId":"320002"}],
                "id":"88163MEMBERBIGLIST",
                "createdBy":"SUPPLIER",
                "modifiedBy":"SUPPLIER",
                "created":"2020-12-14T09:21:31.801-05:00",
                "lastModified":"1607955691801","ttl":-1,
                "status":"COMPLETED",
                "fileSize":0,"size":0,
                "type":"item",
                "deleted":false,
                "migrated":false,
                "downloadStatus":null,
                "displayName":"88163MEMBERBIGLIST"
              }
            ]
          }
      @type {array} download/: blob lists that has been downloaded/requested for download by the user @example "download/": [{
        "name":"72444ClubBigList",
        "id":"72444ClubBigList",
        "modifiedBy":"ADF",
        "lastModified":"1590641048000",
        "ttl":-1,
        "status":"COMPLETED",
        "fileSize":0,"size":660,
        "type":"club",
        "downloads":{"userID":{"request":"2020-08-17T21:58:39.779Z","status":"DOWNLOADED"}},
        "deleted":false,"migrated":false,
        "downloadStatus":"DOWNLOADED",
        "displayName":"72444ClubBigList"
      }]
    },
    @type {object} folderList - (seems always empty) need to check,
    @type {object} bigListError - error in fetching biglistMetadata,
    @type {boolean} loadingGetBigList - signigies if get all biglist API is in progress,
    @type {function} setFullList - setter to set 'blobList' and 'folderList'
 * }
 */
export default function useBigList(baseFolder, user, isDownload) {
  const [fullList, setFullList] = useState({
    blobList: {},
    folderList: {}
  });

  const  { id: userId, homeoffice } = user;
  const [bigListError, setBigListError] = useState(null);
  const [getAllBigList, { loading: loadingGetAllBiglist }] = useLoadingService(getAllBigListService, {
    onCompleted: handleGetBigListComplete,
    onError: handleGetBigListError,
    variables: {
      input: {}
    }
  })

  const { blobList, folderList } = fullList;
  const loadingGetBigList = loadingGetAllBiglist;

  function getType(blobService) {
    let type = '';
    if(blobService.type) {
      type = blobService.type.toLowerCase();
    } else if(blobService.fileType) {
      type = blobService.fileType.toLowerCase();
    }
    return type;
  }
  /**
   *
   * @param {object} data - payload from bigListService API
   */
  async function handleGetBigListComplete(data) {
    setBigListError(null);

    const blobList = {};
    const folderList = {};
    if (data && data.payload) {
      const biglist = data.payload;
      if (biglist && Array.isArray(biglist)) {
        biglist.forEach((blobService) => {
          if (low(userId) === low(blobService.modifiedBy) || homeoffice) {
            const blob = {name: blobService.id, ...blobService};
            blob.status = blob.status && blob.status.toUpperCase();
            blob.status = blob.status===BlobProcessStatus.COMPLETED_WITH_ERRORS|| blob.status === BlobProcessStatus.COMPLETED || blob.status === BlobProcessStatus.FAILED ? blob.status : BlobProcessStatus.LOADING
            blob.downloadStatus = blob.downloads && blob.downloads[userId] ? blob.downloads[userId].status : null;
            blob.lastModified = dateFormat(blobService.lastModified, 'x');
            blob.name = blobService.id;
            blob.displayName = blobService.id;
            blob.type = getType(blobService);
            blob.path = blobService.path || 'download';
            blob.extension= blobService.extension;
            if ((!isDownload && [BlobTypes.ITEM, BlobTypes.CLUB, BlobTypes.CLUB_OVERRIDE, BlobTypes.MEMBER, BlobTypes.BATCH].includes(blob.type))
            || (isDownload && blob.downloads && blob.downloads[userId])) {
              concatList(blob, blobList, isDownload);
            }
          }
        })
      }
    }
    setFullList({
      blobList,
      folderList
    })
  }

  function handleGetBigListError(error) {
    console.error(error)
    setBigListError(error);
  }

  return {
    getAllBigList,
    blobList,
    folderList,
    bigListError,
    loadingGetBigList,
    setFullList
  }
}
