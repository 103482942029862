import orderBy from 'lodash/orderBy';
import getCompartmentbyId from './getCompartmentById';

/*
* pass compartment Json & compartments for the role
* get filtered compartments for that particular role
*/
const getCustomCompartments = (allCompts, roleCompts) => {

  let filteredCompts = [];

  if(roleCompts && allCompts){
    roleCompts.map(compt => {
      const newCompt = getCompartmentbyId(allCompts, compt);
      filteredCompts.push(newCompt)
    })
    filteredCompts = filteredCompts.filter(compt => compt!== undefined)
  }
  /*
  * Sort the compartments based on sequence in ascending order
  */
  return orderBy(filteredCompts, ["sequence"], ["asc"]);
};

export default getCustomCompartments;
