import gql from "graphql-tag";
import { Index } from '../constants/Endpoints';

export const GET_USER = gql`
  mutation {
    user(input: $input) @rest(type : "user", path: "users") {
      payload
    }
  }
`;

export const GET_USER_BY_ID = gql`
  query {
    user(userId: $userId) @rest(type : "user", path: "users/{args.userId}") {
      payload
    }
  }
`;

export const GET_ROLES = gql`
  query {
    roles @rest(type : "roles", path: "users/roles") {
      payload
    }
  }
`;

export const UPDATE_USER = gql`
  mutation updateUser {
    user(input: $input, userId: $userId, roleId: $roleId)
      @rest(
        type: "user"
        path: "users/{args.userId}/roles/{args.roleId}"
        method: "PUT"
      ) {
      status
      errors
      payload
    }
  }
`;

export const UPDATE_ROLES = gql`
mutation updateRoles {
  updateRoles(input: $input, readOnly: $readOnly)
    @rest(
      type: "user"
      path: "users/roles?readOnly={args.readOnly}"
      method: "PUT"
    ) {
    status
    errors
    payload
  }
}
`;

export const LOCAL_USER = gql`
  fragment user on users {
    listUser
  }
`;
