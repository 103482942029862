import {makeStyles} from '@material-ui/core/styles';
import Colors from '../../../theme/Colors';

const styles = {
  root: {
    height: 40
  },
  label: {
    textTransform: 'none',
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal'
  },
  labelSmall: {
    fontSize: 14
  },
  iconSizeSmall: {
    '& > *:first-child': {
      fontSize: 20
    }
  },
  iconSizeMedium: {
    '& > *:first-child': {
      fontSize: 30
    }
  },
  iconSizeLarge: {
    '& > *:first-child': {
      fontSize: 40
    }
  },
  labelContained: {
    fontWeight: 500
  },
  textError: {
    color: Colors.primaryOrange,
    '&:hover': {
      backgroundColor: Colors.subtleOrange
    }
  },
  outlinedError: {
    color: Colors.primaryOrange,
    borderColor: Colors.primaryOrange,
    '&:hover': {
      backgroundColor: Colors.subtleOrange
    }
  },
  containedError: {
    color: Colors.white,
    backgroundColor: Colors.primaryOrange,
    '&:hover': {
      backgroundColor: Colors.lowlightOrange
    }
  }
};

export default makeStyles(styles)
