import { BlobNameCharacterWhitelist, BlobDelimiterReplace } from '../../constants/Constants';

function charWhitelist(name, fileName) {
  let finalName;
  const index = fileName.lastIndexOf('.');
  const extension = index >= 0 ? fileName.slice(index) : '';
  const period = name.lastIndexOf('.');

  let nameWithoutExtension, userExtension;

  if (period >= 0) {
    nameWithoutExtension = name.slice(0, period);
    userExtension = name.slice(period);

    if (userExtension === extension) {
      finalName = nameWithoutExtension;
    } else {
      finalName = nameWithoutExtension + userExtension;
    }
  } else {
    finalName = name;
  }

  finalName = finalName.replace(/\s+/g,' ') // Collapse spaces
  finalName = finalName.replace(new RegExp(BlobNameCharacterWhitelist, 'g'), BlobDelimiterReplace).toUpperCase().slice(0,50);

  if (extension === '.txt') {
    finalName += '.csv';
  } else {
    finalName += extension;
  }

  return finalName;
}

export default charWhitelist;
