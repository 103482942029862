import React from "react";
import Popover from "@material-ui/core/Popover";
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import Tooltip from '@material-ui/core/Tooltip';
import RecentsCard from '../../../widgets/card/RecentsCard';
import { useStyles } from './RecentPagesBtnStyles';

function RecentPagesBtn(){
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [open, setOpen] = React.useState(false);
  const classes = useStyles();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setOpen(true);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setOpen(false);
  };

  return (

    <React.Fragment>
      <Tooltip arrow title="Recently Viewed">
        <AccessTimeIcon
          data-testid="access-time-icon"
          aria-owns={open ? "mouse-over-popover" : undefined}
          aria-haspopup="true"
          style={{marginLeft: 4, marginRight: 4, cursor: "pointer"}}
          onClick={handleClick}
        />
      </Tooltip>
      <Popover
        data-testid="mouse-over-popover"
        id="mouse-over-popover"
        className={classes.popover}
        classes={{
          paper: classes.paper
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right"
        }}
        onClose={handleClose}
        disableRestoreFocus
      >
        <RecentsCard styles={{border: "1px solid #0a0a0a1a", position: "", height: 250}} title="Recent Pages" />
      </Popover>
    </React.Fragment>
  );

}

export default RecentPagesBtn;
