import navTypes from './getNavigationTypes';
import stringCompare from '../stringCompare';

export function createSearchWorkflowPath({ workflowId, rootType, parentId, itemId, templateId, mode, sequence }) {
  return `/${rootType}/workflow/${mode ? workflowId : 0}/${parentId}/${itemId}/${templateId}/${mode ? mode : ''}${sequence === '' ? '' : '?sequence=' + sequence}`;
}

export default (toComp, currComp, rootType, parentPath) => {
  if(toComp && toComp.navTarget){
    if ([navTypes.FRAME, navTypes.PAGE].includes(toComp.navTarget.toLowerCase())) {
      if (parentPath) {
        return `${parentPath}/${toComp.id}`;
      } else if (toComp.isRoot) {
        return `/${toComp.type}/page/${toComp.id}`;
      } else if (currComp && stringCompare(navTypes.PAGE, currComp.navTarget) && currComp.id !== toComp.id) {
        return `${currComp.id}/${toComp.id}`;
      } else {
        return `${toComp.id}`;
      }
    } else if ([navTypes.WORKFLOW, navTypes.TEMPLATE].includes(toComp.navTarget.toLowerCase())) {
      return `/${rootType}/workflow/0/${toComp.id}`;
    } else if([navTypes.BULKUPLOAD].includes(toComp.navTarget.toLowerCase())) {
      return `/${toComp.navTarget}/${toComp.name}`;
    } else {
      return `/${toComp.type}/${toComp.id}`;
    }
  }
}
