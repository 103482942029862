//Convert bytes into a human readable format
export default function convertBytes(bytes, fixed = 0) {
  const kb = 1024;
  const mb = 1048576;
  const gb = 1073741824;

  if (bytes >= gb) {
    return (bytes/gb).toFixed(fixed)+' GB';
  } else if (bytes >= mb) {
    return (bytes/mb).toFixed(fixed)+' MB';
  } else if (bytes >= kb) {
    return (bytes/kb).toFixed(fixed)+' KB';
  } else {
    return (bytes).toFixed(fixed)+'  B';
  }
}
