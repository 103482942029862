import { useEffect } from "react";

const useCustomRenderEvent = (reactPlugin, eventName, eventData) => {
  useEffect(() => {
    if (eventData.name) {
      reactPlugin.trackEvent({ name: eventName }, eventData);
    }
  }, []);
};
export default useCustomRenderEvent;
