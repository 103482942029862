import env from './env'

let userId = env === 'stage' || env === 'prod' ? '' : 'TEST';

//For testing SSO
// export function getCookie(name) {
//     var value = "; " + document.cookie;
//     var parts = value.split("; " + name + "=");
//     if (parts.length === 2) return parts.pop().split(";").shift();
// }
// const token = getCookie('API_TOKEN');
// let userId = token ? 'TEST' : '';

export function setUserId(_userId) {
  userId = _userId;
}

export function getUserId() {
  return userId;
}
