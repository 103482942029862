import React, { useContext } from 'react';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import CardContent from '@material-ui/core/CardContent';
import Avatar from '@material-ui/core/Avatar';
import Colors from '../../theme/Colors';
import CardBody from '../card/card-body';
import styles from './user-details-popover.styles';
import UserComptContext from '../../setup/compartments/compartment.context';
import getUserName from '../../Utils/getUserName';
import { notAvailable } from '../../constants/Constants';

// eslint-disable-next-line import/prefer-default-export
export const UserDetailsPopover = (props) => {
  const { classes, anchorEl } = props;
  const open = Boolean(anchorEl);

  const { user, simulatedUser, simulatingUser } = useContext(UserComptContext);

  const currentUser = simulatingUser ? simulatedUser : user;

  const getUserAvatarCredentials = (name) => {
    return name
      .split(' ')
      .map((p) => {
        return p[0] && p[0].toUpperCase();
      })
      .join('');
  };

  const getUserRole = () => {
    if(currentUser.role && Object.keys(currentUser.role).length > 0) {
      return currentUser.role.description || currentUser.role.name;
    }
    return notAvailable;
  };

  const getSupplierIds = () => {
    return currentUser.userOrgs && currentUser.userOrgs.reduce((res, org, index, orgs) => {
      return `${res.concat(org.vendorId, index !== orgs.length - 1 ? ', ' : '')}`
    }, '')
  };

  if (typeof currentUser.id === 'undefined' || !currentUser.userInfo) {
    return null;
  }

  return (
    <div className={classes.userPopover} style={open ? {} : { opacity: 0, pointerEvents: 'none' }}>
      <Paper>
        <CardBody style={styles.cardBody}>
          <Box style={styles.avatar}>
            <Avatar
              style={{
                color:
                  props.location && props.location.pathname === '/home'
                    ? Colors.white
                    : Colors.black,
              }}
            >
              {getUserAvatarCredentials(getUserName(currentUser))}
            </Avatar>
          </Box>
          <CardContent style={styles.content}>
            <Typography>{`Name: ${currentUser.userInfo.name}`}</Typography>
            <Typography>{`Role: ${getUserRole()}`}</Typography>
            <Typography>{`Organization: ${currentUser.partitionKey}`}</Typography>
            {currentUser.userOrgs ? <Typography style={styles.vendorNames}>{`Suppliers: ${getSupplierIds()}`}</Typography> : null}
          </CardContent>
        </CardBody>
      </Paper>
    </div>
  );
};
