import { useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import usePersistence from '../../hooks/persistence/usePersistence';

// this component handles restoring path after login
function SessionRestore() {
  const location = useLocation();
  const history = useHistory();
  const persistedRoute = usePersistence('home', location.pathname)
  useEffect(() => {
    if (persistedRoute) {
      history.push(persistedRoute)
    }
  }, [persistedRoute])

  return null;
}

export default SessionRestore;
