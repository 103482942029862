import React from 'react';
import {
  Typography,
  ButtonBase
} from '@material-ui/core';
import CloudUploadOutlined from '@material-ui/icons/CloudUploadOutlined';
import SelectFileButton from '../../../widgets/buttons/SelectFileButton/SelectFileButton';
import useStyles from './BigListUpload.styles';

function BigListUpload(props) {

  const { mobileView, onChange, style } = props;

  const classes = useStyles();

  function handleDragOver(event) {
    event.stopPropagation();
    event.preventDefault();
    event.dataTransfer.dropEffect = 'copy';
  }

  function handleFileDrop(event) {
    event.stopPropagation();
    event.preventDefault();

    const dataTransfer = event.dataTransfer;

    if (dataTransfer) {
      const files = [...dataTransfer.files];
      onChange(files);
    }
  }

  function handleFileSelect(event) {
    const target = event.target;
    if (target) {
      const files = [...target.files];
      onChange(files);
      target.value = null;
    }
  }

  return (
    <div style={style} className={classes.dropZone} onDragOver={handleDragOver} onDrop={handleFileDrop} id="dropZone">
      <CloudUploadOutlined className={classes.uploadIcon} />
      <div className={classes.uploadText}>
        {!mobileView ? <Typography variant='h4' className={classes.dropFiles}>Drop files to upload, or</Typography> : null}
        <SelectFileButton fileId='file-upload-input-browse-files' onChange={handleFileSelect} accept='.csv,.txt'>
          <ButtonBase className={classes.browseFilesButton} component='div' disableRipple disableTouchRipple>
            <Typography role="button" variant='h4' className={classes.browseFiles}>{mobileView ? 'Browse Files' : 'browse'}</Typography>
          </ButtonBase>
        </SelectFileButton>
      </div>
    </div>
  )
}

export default React.memo(BigListUpload);
