import env from "../../config/env";
import Colors from "../../theme/Colors";

export const headerHeight = 48;

// eslint-disable-next-line import/prefer-default-export
export  const styles = theme => ({
  root: {
    zIndex: 100,
    height: headerHeight,
    '& + .routes-container': {
      height: `calc(100% - ${headerHeight}px)`
    }
  },
  rootBanner: {
    height: 96,
    '& + .routes-container': {
      height: 'calc(100% - 96px)'
    }
  },
  list: {
    width: 250,
    height: `calc(100% - ${headerHeight}px)`
  },

  icon: {
    margin: 2
  },
  top: {
    displey: "flex"
  },

  grow: {
    flexGrow: 1
  },

  menuButton: {
    marginLeft: -12,
    marginRight: 20
  },

  whiteTextColor: {
    color: "white"
  },

  blackTextColor: {
    color: "black"
  },

  appBar: {
    height: headerHeight,
    position: 'relative',
    zIndex: 1100
  },

  appBarWhite: {
    backgroundColor: "white",
    borderBottom: "1px solid #eeeeee"
  },

  appBarBlue: {
    background: `${env !== 'prod' ? Colors.primaryDarkGreen : Colors.primaryBlue}`
  },

  appBarButton: {
    borderRight: "1px solid #ffffff",
    borderRadius: "0%"
  },

  topNav: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100vw",
    height: '100%'
  },

  formControl: {
    minWidth: 120
  },

  popover: {
    pointerEvents: "none"
  },

  paper: {
    paddng: 35
  },

  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    height: 100,
    padding: "0 20px"
  },

  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: "0 8px",
    ...theme.mixins.toolbar,
    justifyContent: "flex-end"
  },

  disabled: {
    color: "lightgray",
    cursor: "not-allowed"
  },

  cardmedium: {
    borderRadius: '10px',
    margin: theme.spacing(1),
    width: 300,
    height: 100,
    marginTop: 20
  },
  headerLeft: {
    display: 'flex',
    flexDirection: 'row',
    height: '100%',
    alignItems: 'center'
  },
  toolbar: {
    height: headerHeight,
    minHeight: headerHeight,
    padding: '0 8px',
    '@media(min-width: 768px)': {
      padding: '0 1em'
    },
    '@media(min-width: 1024px)': {
      padding: '0 2em'
    },
    boxSizing: 'border-box'
  },
  navItems: {
    '& button': {
      minWidth: 'auto'
    },
    '& div': {
      margin: 'auto'
    }
  },
  topNavContainer: {
    display: 'flex'
  },
  drawerMode: {
    flexDirection: 'column',
    '& a': {
      marginBottom: 8
    },
    '& div': {
      marginBottom: 8
    }
  },
  topNavMode: {
    fontSize: '13px',
    alignItems: 'center',
    '& a': {
      marginLeft: 8
    },
    '& > div': {
      marginLeft: 8
    }
  },
  buttonIcon: {
    marginRight: 8,
    fontSize: 16
  },
  noMargin: {
    margin: 0
  },
  lastNav: {
    order: 9
  },
  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'transparent',
    visibility: 'hidden',
    opacity: 0,
    transition: 'all 0.35s'
  },
  visible: {
    visibility: 'visible',
    backgroundColor: '#00000088',
    opacity: 1,
    zIndex: 2
  },
  backdropRoot: {
    display: 'none',
    backgroundColor: 'red'
  },
  userPopover: {
    position: 'absolute',
    right: 8,
    transition: 'opacity 0.2s, transform 0.5s',
    paddingTop: 8
  }
});
