import { makeStyles } from '@material-ui/core';
import Colors from '../../../theme/Colors';
import { fontSizeReg, fontSizeXS } from '../../../theme/theme';

const largeWidth = 564;
const smallWidth = 400;

const styles = {
  root: {
    padding: 16,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    boxSizing: 'border-box',
    width: '100%',
    maxWidth: 800,
    margin: 'auto'
  },
  panel: {
    width: '100%',
    marginTop: 8,
    marginBottom: 8
  },
  expansionSummaryContainer: {
    height: 56,
    padding: 8,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start'
  },
  editHeading: {
    width: '100%',
    marginBottom: '16px',
    fontSize: '16px',
    fontWeight: 500,
    backgroundColor: '#f5f5f5',
    height: '64px',
    paddingTop: '16px',
    boxSizing: 'border-box'
  },
  listContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    paddingBottom: 16
  },
  fileListContainer: {
    width: '100%',
    maxWidth: largeWidth,
    height: '100%',
    minHeight: 312,
    maxHeight: 372,
    // overflow: 'auto',
    paddingLeft: 16,
    paddingRight: 16,
    paddingBottom: 16
  },
  fileSingleContainer: {
    width: '100%',
    paddingBottom: 16,
    display: 'flex',
    justifyContent: 'center'
  },
  actionContainer: {
    marginBottom: 16,
    width: '100%',
    maxWidth: smallWidth
  },
  actionTitleContainer: {
    marginBottom: 16,
    width: '100%',
    border: 0,
    borderBottomWidth: 1,
    borderColor: Colors.grey300,
    borderStyle: 'solid'
  },
  actionButtons: {
    textTransform: 'capitalize',
    marginTop: 8,
    marginBottom: 8
  },
  selectFileContainer: {
    width: '100%',
    maxWidth: largeWidth,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  selectFileText: {
    paddingRight: 8
  },
  selectFileButton: {
    width: 199
  },
  uploadSwitchContainer: {
    paddingBottom: 16,
    width: '100%',
    maxWidth: smallWidth
  },
  buttonContainer: {
    paddingTop: 16,
    width: '100%',
    maxWidth: largeWidth
    // marginTop: 'auto'
  },
  uploadContainer: {
    width: '100%',
    maxWidth: largeWidth,
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    paddingBottom: 16
  },
  dropZone: {
    display: 'flex',
    boxSizing: 'border-box',
    border: '2px dashed #bbb',
    width: '100%',
    padding: 16,
    flexDirection: 'row',
    textAlign: 'center',
    alignItems: 'center',
    justifyContent: 'center'
  },
  uploadIcon: {
    fontSize: 40,
    color: Colors.grey300
  },
  uploadText: {
    display: 'flex',
    paddingLeft: 8,
    paddingRight: 0,
    paddingTop: 16,
    paddingBottom: 16,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center'
  },
  dropFiles: {
    paddingTop: 16,
    paddingBottom: 16,
    paddingLeft: 16,
    paddingRight: 4
  },
  browseFiles: {
    color: Colors.primaryBlue,
    paddingTop: 16,
    paddingBottom: 16
  },
  browseFilesButton: {
    '&:hover $browseFiles': {
      color: Colors.highlightBlue
    },
    '&:active $browseFiles': {
      color: Colors.lowlightBlue
    }
  },
  buttonOutlined: {
    '&:hover': {
      borderWidth: 2,
      borderColor: Colors.primaryBlue,
      backgroundColor: Colors.subtleBlue
    },
    borderWidth: 2,
    borderColor: Colors.primaryBlue,
    color: Colors.primaryBlue,
    fontSize: 19,
    textTransform: 'none',
    borderRadius: 5
  },
  rippleRoot: {
    borderRadius: 0
  },
  ripple: {
    color: Colors.primaryBlue
  },
  uploadButton: {

  },
  listField: {
    margin: '0 8px'
  },
  listFieldMobile: {
    margin: '4px 0'
  },
  inputList: {
    height: '100%',
    minHeight: 312
  },
  typeButton: {
    width: 176,
    textTransform: 'capitalize'
  },
  hidden: {
    display: 'none'
  },
  buttonHidden: {
    visibility: 'hidden'
  },
  fileDetails: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%'
  },
  closeSingleFile: {
    float: 'right'
  },
  closeIconContainer: {
    paddingRight: 16
  },
  file: {
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  fileIcon: {
    fontSize: 32,
    paddingRight: 8
  },
  closeButton: {
    marginLeft: 'auto'
  },
  actionTitle: {
    textTransform: 'capitalize',
    paddingBottom: 4
  },
  entry: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center'
  },
  entryMobile: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  entryList: {
    width: '100%',
    marginTop: '16px',
    overflowX: 'hidden',
    maxHeight: '262px', overflowY: 'scroll'
  },
  entryCell: {
    height: 24,
    alignItems: 'center',
    whiteSpace: 'nowrap'
  },
  submitButtonContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  errorText: {
    paddingRight: 8
  },
  addButtonContainer: {
    width: 120,
    display: 'flex',
    flexDirection: 'row'
  },
  addButtonContainerMobile: {
    width: '100%',
    marginTop: 4,
    display: 'flex',
    flexDirection: 'row'
  },
  addButtonMobile: {
    width: '100%'
  },
  searchContainer: {
    width: '100%',
    height: '280px'
  },
  searchIconContainer: {
    // height: 32,
    width: 120,
    display: 'flex'
  },
  searchSuccessIcon: {
    color: Colors.primaryGreen
  },
  searchFailIcon: {
    color: Colors.red
  },
  searchIcon: {
    marginLeft: 'auto'
  },
  selectInput: {
    width: 112,
    verticalAlign: 'middle'
  },
  gridContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column'
  },
  gridItem: {
    width: '100%',
    marginTop: 4,
    marginBottom: 4
  },
  searchText: {
    width: '100%',
    textAlign: 'center',
    marginBottom: 48
  },
  searchbar: {
    marginBottom: 0,
    marginLeft: 8,
    marginRight: 8
  },
  importButtonContainer: {
    paddingTop: 8,
    paddingBottom: 8,
    width: '100%',
    display: 'flex'
  },
  tableSticky: {
    position: 'sticky',
    background: 'white',
    top: 0,
    zIndex: 4
  },
  table: {
    position: 'relative',
    borderCollapse: 'separate',
    tableLayout: 'fixed'
  },
  fileName: {
    // maxWidth: 232
  },
  actionSelectButtons: {
    marginLeft: 4,
    marginRight: 4
  },
  fileNameTable: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  searchTableContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    height: 0
  },
  editField: {
    width: '100%'
  },
  editMemberRoot: {
    padding: 16,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    boxSizing: 'border-box',
    width: '100%'
  },
  paper: {
    maxHeight: '350px',
    width: '46%'
  },
  entries: {
    maxHeight: '350px',
    height: '350px',
    "& $editHeading": {
      width: 'auto',
      marginBottom: '16px'
    }
  },
  editText: {
    fontSize: fontSizeReg,
    textAlign: 'right'
  },
  importButton: {
    margin: '-8px 8px'
  },
  searchResults: {
    display: 'flex',
    marginTop: '16px',
    justifyContent: 'flex-start',
    alignItems: 'baseline',
    paddingLeft: '5%'
  },
  smallFont: {
    fontSize: fontSizeXS
  },
  flexed: {
    display: 'flex'
  },
  leftMargin: {
    marginLeft: '8px'
  },
  bottom: {
    bottom: 0,
    position: 'absolute',
    width: '100%'
  },
  memberSearchContainer: {
    display: 'flex',
    flexDirection: 'row', width: '100%', justifyContent: 'space-between'
  },
  entriesHeader: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'space-between',
    height: '64px',
    backgroundColor: '#0071e9',
    color: 'white',
    boxSizing: 'border-box',
    paddingTop: '8px',
    paddingLeft: '8px',
    paddingRight: '8px',
    '& $text': {
      color: 'white'
    }
  },
  memberEntriesContainer: {
    margin: '32px 0',
    maxHeight: '350px',
    width: '100%',
    border: '1px solid #cecece',
    borderTop: 'none'
  },
  editBtnContainer: {
    marginRight: '16px',
    justifyContent: 'flex-end'
  },
  borderBtm: {
    borderBottomWidth : '2px solid black'
  },
  mainText: {
    marginBottom: '16px',
    fontSize: fontSizeReg,
    textAlign: 'start'
  },
  bulkBtnContainer: {
    width: '100vw',
    bottom: 0,
    height: '64px',
    display: 'flex',
    zIndex: 3,
    position: 'fixed',
    alignItems: 'center',
    backgroundColor: '#ffffff',
    padding: '0 16px',
    justifyContent: 'flex-end'
  },
  bulkUploadContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    padding: '8px'
  },
  bulkText: {
    fontSize: fontSizeReg,
    padding: '16px'
  },
  bulkContainer: {
    paddingTop: 16,
    width: '100%'
  }

}

export default makeStyles(styles)
