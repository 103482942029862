import Colors from '../../theme/Colors'

const styles = {
  root: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  circleMainRoot: {
  },
  circleMain: {

  },
  circleMainColor: {
    color: Colors.primaryBlue
  },
  circleSecondaryMainColor: {
    color: Colors.white
  },
  circleBackgroundRoot: {
    position: 'absolute'
  },
  circleBackground: {

  },
  circleBackgroundColor: {
    color: Colors.subtleBlue
  },
  circleSecondaryBackgroundColor: {
    color: Colors.highlightBlue
  },
  text: {
    position: 'absolute',
    textAlign: 'center'
  },
  icon: {
    position: 'absolute',
    textAlign: 'center',
    fontSize: 38
  },
  circleSvg: {
    strokeLinecap: 'round'
  }
}

export default styles;
