import { makeStyles } from '@material-ui/core';
import Colors from '../../../theme/Colors';

const styles = theme => ({
  root: {
    padding: 8
  },
  card: {
    display: 'flex',
    flexDirection: 'row',
    textAlign: 'left'
  },
  cardCircular: {
    display: 'flex',
    flexDirection: 'row',
    textAlign: 'left',
    width: 300
  },
  leftContainer: {
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
    padding: 16,
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column'
    }
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1
  },
  topContainer: {
    display: 'flex',
    flexDirection: 'row'
  },
  fileContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: 200,
    paddingRight: 16
  },
  fileContainerCircular: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    paddingTop: 8,
    paddingLeft: 8,
    paddingRight: 8,
    width: 140
  },
  progressContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    flex: 10,
    paddingRight: 8
  },
  progressContainerCircular: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    flex: 1,
    paddingTop: 8,
    paddingRight: 8
  },
  buttonContainer: {
    width: 50
  },
  fileName: {
    paddingLeft: 8,
    width: 160
  },
  fileNameCircular: {
    paddingLeft: 8,
    width: 92
  },
  fileIcon: {
    fontSize: 48
  },
  progressBarContainer: {
    display: 'block'
  },
  progressText: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    height: 24
  },
  progressTextCircular: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingLeft: 16,
    paddingRight: 16,
    paddingBottom: 8
  },
  estimatedTime: {
    textAlign: 'right'
  },
  progressRoot: {
    height: 10,
    borderRadius: 20,
    backgroundColor: Colors.subtleBlue
  },
  progressBar: {
    borderRadius: 20,
    backgroundColor: Colors.primaryBlue,
    transition: 'transform 0.1s linear'
  },
  text: {
    height: 24,
    paddingLeft: 0,
    paddingRight: 0
  },
  speedText: {
    paddingLeft: 48
  },
  checkCircleIcon: {
    color: Colors.primaryGreen
  },
  cancelIconLoading: {
    color: Colors.primaryOrange
  },
  cancelIcon: {}
})

export default makeStyles(styles);
