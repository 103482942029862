import { baseURL, baseHeaders } from '../../constants/Endpoints';

async function getAllBiglistService(type='list') {
  const body = {}
  const getAllBiglistResponse = await fetch(`${baseURL}search?searchType=${type}`, {
    method: 'POST',
    body: JSON.stringify(body),
    headers: baseHeaders()
  }).then(function (res) {
    return res.json();
  }).then(function (data) {
    return data;
  });

  return getAllBiglistResponse;
}

export default getAllBiglistService
