import React, { Fragment, useContext, useState } from 'react';
import { Link, NavLink, withRouter } from 'react-router-dom';

import {
  Button,
  Collapse,
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  ListSubheader,
  Paper,
  SwipeableDrawer,
  Typography
} from '@material-ui/core';
import isEmpty from 'lodash/isEmpty';
import MenuIcon from "@material-ui/icons/Menu";
import ArrowDropDown from '@material-ui/icons/ArrowDropDown';
import ArrowRight from '@material-ui/icons/ArrowRight';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import Skeleton from 'react-loading-skeleton';
import TopNav from '../../Common/TopNav';
import createPath from '../../../Utils/navigation/createPath';

import useStyles from './HeaderNavigation.styles';
import getRootCompartments from '../../../Utils/compartments/getRootCompartments';
import getCompartmentById from '../../../Utils/compartments/getCompartmentById';
import UserComptContext from '../../../setup/compartments/compartment.context';
import Colors from '../../../theme/Colors';

import { Labels } from '../../../constants/labels';
import env from '../../../config/env';

function HeaderNavigation(props) {
  const [openDrawer, setOpenDrawer] = useState(false);
  const [openList, setOpenList] = useState([]);
  const classes = useStyles();
  const { drawer, showRightNav, rootType, setOverlay } = props;
  const home = !rootType || rootType === 'home';
  // get all compartments
  const { compartments: allCompartments, user, simulatedUser, simulatingUser } = useContext(UserComptContext);
  const currentUser = simulatingUser ? simulatedUser : user;
  const pjson = require('../../../../package.json');

  const toggleList = (id, depth) => (event) => {
    event.stopPropagation();
    event.preventDefault();
    setOpenList((openList) => {
      const newOpenList = [...openList];
      if (openList[depth] === id) {
        newOpenList[depth] = null;
      } else {
        newOpenList[depth] = id;
      }
      for (let i = depth + 1; i < openList.length; i++) {
        newOpenList[i] = null;
      }
      return newOpenList;
    })
  }

  function renderChild(childId, parentCompartment, depth, rootCompartment, parentPath) {
    const compartment = getCompartmentById(allCompartments, childId)
    if (compartment) {
      const { name, description, navTarget, children } = compartment;
      const path = createPath(compartment, null, rootCompartment.type, parentPath);
      return (
        <div className={classes.childButtonContainer} key={compartment.id}>
          <Link to={path}>
            <Button
              id="renderChildBtnId"
              onClick={() => setOpenDrawer(false)}
              classes={{
                root: classes.childButton,
                label: classes.rootButtonLabel
              }}
              variant='text'
            >
              <div>
                <Typography className={classes.buttonChildText}>
                  {name}
                </Typography>
                <Typography className={classes.buttonChildDescriptionText}>
                  {description}
                </Typography>
              </div>
              {navTarget === 'page' && children.length > 0 ? (
                <ArrowRight className={classes.rootArrow} />
              ) : null}
            </Button>
          </Link>
          {navTarget === 'page' && children.length > 0 ? (
            renderChildren(compartment, depth + 1, rootCompartment, path)
          ) : null}
        </div>
      );
    }
    return null;
  }

  function renderChildren(compartment, depth, rootCompartment, path) {
    const { children } = compartment;

    return (
      <div
        className={depth === 0 ? classes.childrenRoot : classes.childrenRootRight}
      >
        <Paper className={classes.childContainer}>
          {children.map((childId) => renderChild(childId, compartment, depth, rootCompartment, path))}
        </Paper>
      </div>
    );
  }

  function renderDrawerItem(compartment, parentCompartment, rootCompartment, depth, parentPath) {
    if (compartment) {
      const { name, description, navTarget, children, type, isRoot } = compartment;
      const id = compartment.id + parentCompartment.id + rootCompartment.id;
      const open = openList[depth] === id;
      const path = createPath(compartment, null, rootCompartment.type, parentPath);
      const current = isRoot && type === rootType;
      return (
        <Fragment key={id}>
          <Link to={path}>
            <ListItem
              button
              onClick={() => setOpenDrawer(false)}
              className={classes.listItem}
              style={{ paddingLeft: depth * 16 + 16 }}
            >
              <ListItemText primary={name} secondary={description} className={current ? classes.selectedRootDrawer : ''} />
              {navTarget === 'page' && children.length > 0 ? (
                <ListItemSecondaryAction>
                  <IconButton onClick={toggleList(id, depth)}>
                    {open ? <ExpandLess /> : <ExpandMore />}
                  </IconButton>
                </ListItemSecondaryAction>
              ) : null}
            </ListItem>
          </Link>
          {navTarget === 'page' && children.length > 0 ? (
            <Collapse in={open} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                {children.map((childId) => {
                  const childCompartment = getCompartmentById(allCompartments, childId)
                  return renderDrawerItem(childCompartment, compartment, rootCompartment, depth + 1, path);
                })}
              </List>
            </Collapse>
          ) : null}
        </Fragment>
      )
    }
    return null;
  }

  function renderSliderDetails() {
    return (
      <div className={classes.sliderDetailsContainer}>
        <TopNav {...props} />
      </div>
    );
  }

  function renderRootCompartments(compartment) {
    const { name, navTarget, children, type } = compartment;

    const path = createPath(compartment);
    const current = type === rootType;
    const mouseEvents = !home ? {
      onMouseEnter: () => setOverlay(true),
      onMouseLeave: () => setOverlay(false)
    } : {};

    return (
      <div {...mouseEvents} className={classes.rootButtonContainer} key={compartment.id + current} id="rootCompartmentDivId">
        <Link to={path}>
          <Button
            id="rootButtonId"
            onClick={() => setOpenDrawer(false)}
            classes={{
              root: classes.rootButton,
              label: classes.rootButtonLabel
            }}
            variant='text'
          >
            <Typography className={classes.buttonText} style={{ color: current ? (env !== "prod") ? Colors.currentMenuLightYellow : Colors.primaryBlue : (env !== "prod") ? Colors.white : '#424242' }}>
              {name}
            </Typography>
            {navTarget === 'page' && children.length > 0 ? (

              <ArrowDropDown className={classes.rootArrow} />
            ) : null}
          </Button>
        </Link>
        {navTarget === 'page' && children.length > 0 ? (
          renderChildren(compartment, 0, compartment, path)
        ) : null}
      </div>
    );
  }

  function renderImage(imgType) {

    let imgUrl = "";

    if (imgType === "new-blue") {
      imgUrl = "/logo-blue-no-borders.png";
    } else if (imgType === "new-white") {
      imgUrl = "/logo-light.png";
    } else {
      imgUrl = (home) ? '/logo-light.png' : "/logo-blue-no-borders.png";
    }

    return (
      <NavLink to="/home">
        <img
          className={classes.logo}
          alt='logo'
          src={imgUrl}
        />
      </NavLink>
    )
  }

  if ((allCompartments && allCompartments.length) || (allCompartments && !isEmpty(currentUser))) {
    const rootCompartments = getRootCompartments(allCompartments)
      .filter(({ params }) => !params || !params.hideHeader)
    const versionLabel = (`V ${pjson.version}`)
    if (drawer) {
      return (
        <>
          <div className={`${classes.root} ${!showRightNav ? classes.fullHeaderNav : ''}`}>
            <IconButton
              className={classes.menuButton}
              style={{ color: (home) ? "white" : "rgba(0, 0, 0, 0.87)" }}
              aria-label="Menu"
              onClick={() => setOpenDrawer(true)}
            >
              <MenuIcon />
            </IconButton>
            {(home) ? renderImage('new-white') : ""}
            <div className="content-logo-text" style={{ color: (home) ? "white" : "rgba(0, 0, 0, 0.87)" }}>{Labels.APP_TITLE}</div>
          </div>
          <SwipeableDrawer
            open={openDrawer}
            onOpen={() => setOpenDrawer(true)}
            onClose={() => setOpenDrawer(false)}
            classes={{ paper: classes.drawer }}
          >
            <List
              component="nav"
              aria-labelledby="nested-list-subheader"
              subheader={(
                <>
                  <ListSubheader component="div" id="nested-list-subheader" className={classes.drawerHeader}>
                    {
                      renderImage("new-blue")
                    }
                    <NavLink to="/home">
                      <div className={classes.titleDrawer}>
                        {Labels.APP_TITLE}
                      </div>
                    </NavLink>
                  </ListSubheader>
                </>
              )}
              className={classes.listRoot}
            >
              {rootCompartments.map((compartment) => renderDrawerItem(compartment, compartment, compartment, 0))}
            </List>
            {!showRightNav ? renderSliderDetails() : null}
          </SwipeableDrawer>
        </>
      )
    } else {
      return (
        <div className={classes.root}>
          {renderImage()}
          <Fragment>
            <NavLink to="/home">
              <div className={"content-logo-text " + (!home && env === "prod" ? classes.logoBlue : classes.logoWhite)}>
                {Labels.APP_TITLE}
                <span style={{ fontSize: "11px", marginLeft: "10px" }}>{versionLabel}</span>
              </div>
            </NavLink>
          </Fragment>
          {
            !home ?
              rootCompartments.map(renderRootCompartments)
              : null
          }
        </div>
      )
    }
  } else {
    if (drawer) {
      return (
        <div className={classes.root}>
          <Skeleton
            wrapper={({ children }) => <div className={classes.skeletonWrapper}>{children}</div>}
            height={36}
            width={256}
          />
        </div>
      )
    } else {
      return (
        <div className={classes.root}>
          {new Array(5).fill(0).map((_, i) => (
            <div className={classes.rootButtonContainer} key={i}>
              <Skeleton
                wrapper={({ children }) => <div className={classes.skeletonWrapper}>{children}</div>}
                height={36}
                width={120}
              />
            </div>
          ))}
        </div>
      )
    }
  }
}

export default withRouter(HeaderNavigation);
