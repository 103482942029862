import { baseURL, baseHeaders } from '../../constants/Endpoints';

async function createFilesMetadataService(name, internalName, extension) {
  const body = {
    id: name,
    fileType: 'BATCH',
    readyToDownload: true,
    path: 'batch/file',
    internalName,
    extension: extension && extension.replace(/\./g, ""),
  };
  return fetch(`${baseURL}files/batch`, {
    method: 'POST',
    body: JSON.stringify(body),
    headers: baseHeaders(),
  }).then(res => res.json()).then(data => data).catch(error => error);
}

export default createFilesMetadataService;
