import React from 'react';

function SelectFileButton(props) {
  const { fileId, onChange, children, className, style } = props;
  const acceptedFileFormats = props.accept?{accept:props.accept}:{}
  return (
    <>
      <input
        data-testid='file-selected'
        id={fileId}
        multiple={props.multiple ? props.multiple : false}
        type='file'
        onChange={onChange}
        hidden
        {...acceptedFileFormats}
      />
      <label htmlFor={fileId} className={className} style={style}>
        {children}
      </label>
    </>
  );
}

export default SelectFileButton;
