import React from 'react';
import { useSnackbar } from 'notistack';
import Notification from '../widgets/snackbar/Notification';

function useCustomSnackbar() {
  const snackbar = useSnackbar();

  function newSnackbar({title = '', description = '', persist = false, onDismiss, variant = 'info', actions, duration = 5000}) {
    snackbar.enqueueSnackbar(`${description}`, {
      persist: persist,
      autoHideDuration: duration,
      anchorOrigin: {
        horizontal: 'right',
        vertical: 'top'
      },
      // eslint-disable-next-line react/display-name
      content: (key, message) => {
        let mappedActions;
        if (actions) {
          mappedActions = actions.map(action => {
            return {
              label: action.label,
              onClick: () => {
                if (action.onClick) {
                  action.onClick();
                }
                if (action.dismiss) {
                  snackbar.closeSnackbar(key)
                }
              }
            }
          })
        }
        return (
          <Notification
            id={key}
            message={message}
            title={title}
            variant={variant}
            actions={mappedActions ? mappedActions : [
              {
                label: 'Dismiss',
                onClick: () => {
                  if (onDismiss) {
                    onDismiss();
                  }
                  snackbar.closeSnackbar(key)
                }
              }
            ]}
          />
        )
      }
    })
  }


  return { ...snackbar, newSnackbar };
}

export default useCustomSnackbar;
