import React, { useState, useContext, useMemo } from "react";
import Typography from '@material-ui/core/Typography';
import UserComptContext from '../../setup/compartments/compartment.context';
import useStyles from './RequestAccess.styles'
import CustomButton from '../../widgets/buttons/CustomButton/CustomButton';
import dateFormat from '../../Utils/date/dateFormat';
import requestRoleService from '../../services/user/RequestRoleService';
import useLoadingService from '../../hooks/useLoadingService';
import { UserStatus } from '../../constants/Constants';

function RequestAccess() {
  const { user, simulatedUser, simulatingUser } = useContext(UserComptContext);
  const [responseUser, setResponseUser] = useState(false);
  const classes = useStyles();
  const [requestRole, { loading }] = useLoadingService(requestRoleService, {
    onCompleted: handleOnCompleted
  });
  const currentUser = simulatingUser ? simulatedUser : user

  function checkForAction(presentUser,hasUsers) {
    if (presentUser.auditTrail) {
      const lastAction = presentUser.auditTrail[presentUser.auditTrail.length - 1];
      if (lastAction && lastAction.event === UserStatus.REQUESTED) {
        const dateRequested = new Date(lastAction.createdTS).valueOf();
        const currentDate = Date.now();
        return currentDate > (dateRequested + (1000 * 60 * 60 * 24)) ? { canRequest: true, requestedDate: dateRequested, hasUsers } : { canRequest: false, requestedDate: dateRequested, hasUsers }
      }
    }
  }

  const { canRequest, requestedDate, hasUser } = useMemo(() => {
    if (currentUser) {
      const hasUsers = Object.keys(currentUser).length > 0;
      checkForAction(currentUser,hasUsers);
      return { canRequest: true, requestedDate: null, hasUser: hasUsers }
    }
    return { canRequest: true, requestedDate: null, hasUser: false };
  }, [currentUser])

  function handleOnCompleted() {
    setResponseUser(true);
  }

  function requestAccess() {
    requestRole(currentUser.userId);
  }

  return (
    <div className={classes.root}>
      <div className={classes.card}>
        <div className={classes.messageContainer}>
          <Typography className={classes.text} variant='h4'>Sorry you do not have access</Typography>
        </div>
        <CustomButton
          className={classes.button}
          onClick={requestAccess}
          label='Request for Access'
          variant='contained'
          color='secondary'
          disabled={!canRequest || !hasUser || loading || responseUser}
        />
        {requestedDate ? (
          <Typography className={classes.lightText}>
            {`Access Requested on ${dateFormat(requestedDate)}`}
          </Typography>
        ) : null}
      </div>
    </div>
  );
}


export default RequestAccess;
