import gql from "graphql-tag";

const GET_ALL_BIG_LIST = gql`
mutation {
  biglist(input: $input) @rest(type: "getAllBigList", path: "biglist/search", method: "POST") {
    status,
    header, 
    errors,
    payload
  }
}`;

const SEARCH_BIGLIST_FOR_ID = gql`
mutation($name: String!,$id: String!) {
  searchBigList(name: $name, id: $id) @rest(type: "searchBigList", path: "biglist/{args.name}/{args.id}") {
    status,
    header, 
    errors,
    payload
  }
}`;

const DELETE_BIGLIST = gql`
mutation($name: String!) {
  deleteBigList(name: $name) @rest(type: "deleteBigList", path: "biglist/{args.name}", method: "DELETE") {
    status,
    header, 
    errors,
    payload
  }
}`;


const UPDATE_BIGLIST = gql`
mutation updateBiglist {
  updateBigList(input: $input) @rest(type: "updateBigList", path: "biglist", method: "PUT") {
    status,
    header, 
    errors,
    payload
  }
}`;

const REMOVE_BIGLIST = gql`
mutation removeBiglist {
  updateBigList(input: $input) @rest(type: "updateBigList", path: "biglist", method: "DELETE") {
    status,
    header, 
    errors,
    payload
  }
}`;

const PREPARE_DOWNLOAD = gql`
mutation prepareBiglistDownload($name: String!) {
  prepareDownload(input: $input, name: $name) @rest(type: "prepareDownload", path: "biglist/{args.name}/download", method: "POST") {
    status,
    header, 
    errors,
    payload
  }
}`;

const HIDE_DOWNLOAD_NOTIFICATION = gql`
mutation hideDownloadNotification($name: String!, $type: String!) {
  downloadNotification(input: $input, name: $name, type: $type) @rest(type: "downloadNotification", path: "files/{args.type}/{args.name}/download", method: "GET") {
    status,
    header,
    errors,
    payload
  }
}`;

const BIGLIST_REDIRECT = gql`
mutation biglistRedirect($name: String!) {
  biglistRedirect(input: $input, name: $name) @rest(type: "biglistRedirect", path: "biglist/{args.name}/download/redirect", method: "GET") {
    status,
    header, 
    errors,
    payload
  }
}`;

export {
  GET_ALL_BIG_LIST,
  SEARCH_BIGLIST_FOR_ID,
  DELETE_BIGLIST,
  UPDATE_BIGLIST,
  PREPARE_DOWNLOAD,
  HIDE_DOWNLOAD_NOTIFICATION,
  BIGLIST_REDIRECT,
  REMOVE_BIGLIST
}
