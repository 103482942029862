import { makeStyles } from '@material-ui/core';
import Colors from '../../theme/Colors';
import { fontMedium } from '../../theme/theme';

const styles = () => ({
  root: {
    width: 360
  },
  paper: {
    padding: 16,
    paddingBottom: 8
  },
  icon: {
    color: Colors.primaryBlue,
    fontSize: '1.25rem'
  },
  iconError: {
    color: Colors.red
  },
  iconSuccess: {
    color: Colors.primaryGreen
  },
  messageContainer: {
    marginRight: 32,
    width: 298
  },
  iconContainer: {
    marginRight: 8,
    height: 16
  },
  buttonContainer: {
    marginLeft: 4
  },
  button: {

  },
  title: {
    fontWeight: fontMedium
  },
  buttonLabel: {
    color: Colors.primaryBlue
  },
  buttonLabelError: {
    color: Colors.red
  },
  buttonLabelSuccess: {
    color: Colors.primaryGreen
  },
  message: {
    textOverflow: 'ellipsis',
    overflow: 'hidden'
  },
  scrollOverflow: {
    maxHeight: 256,
    overflow: 'auto'
  }
});

export default makeStyles(styles);
