import React, { useEffect } from 'react';
import { Select, MenuItem, FormControl, InputLabel, Checkbox, FormLabel, FormGroup, FormControlLabel, FormHelperText } from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';
import clsx from 'clsx';
import useStyle from './SelectInputStyles';
import TextInput from './TextInput';
import MultilineInput from './MultilineInput';
import getValueFromOptions from '../../Utils/select/getValueFromOptions'
import { low } from '../../constants/Constants';

function SelectInput(props) {
  const classes = useStyle();
  const { options, value = props.multiple ? [] : null, onChange, label, placeholder, margin, fullWidth, className, multiple, disabled, required, error, style } = props;
  const inputLabel = React.useRef(null);
  const [labelWidth, setLabelWidth] = React.useState(0);
  const [open, setOpen] = React.useState(false);
  useEffect(() => {
    setLabelWidth(inputLabel && inputLabel.current && inputLabel.current.offsetWidth);
  }, []);
  const localValue = value ? value : (multiple ? [] : (placeholder ? '' : (options && options[0] ? options[0].value : '')));
  function onClick(event) {
    event.stopPropagation();
    event.preventDefault();
    setOpen(true);
  }

  function onBackdropClick(event) {
    event.stopPropagation();
    event.preventDefault();
    setOpen(false);
  }

  function handleOnChange(event) {
    event.stopPropagation();
    event.preventDefault();
    if (!multiple) {
      setOpen(false);
    }
    onChange(event, getValueFromOptions(options, event.target.value, true).label);
  }

  function handleMultiChange(event) {
    onChange(event);
  }

  const select = (
    <Select
      value={localValue}
      classes={{
        root: classes.selectRoot,
        icon: clsx(classes.icon, { [classes.iconDisabled]: disabled }),
        select: classes.select
      }}
      IconComponent={ExpandMore}
      renderValue={(value) => value && value.length > 0 ? (options ? getValueFromOptions(options, value, true).label : value) : <span className={classes.placeholdText}>{placeholder}</span>}
      displayEmpty
      MenuProps={{
        onBackdropClick: onBackdropClick
      }}
      open={open}
      variant='outlined'
      onChange={handleOnChange}
      labelWidth={labelWidth}
      multiple={multiple}
      disabled={disabled}
      onClick={disabled ? null : onClick}
      input={multiple ? (
        <MultilineInput
          fullWidth
          InputProps={{ labelWidth: labelWidth }}
          InputLabelProps={{ shrink: true }}
          SelectProps={{
            classes: {
              root: classes.multiSelectRoot
            }
          }}
          select
          error={error}
        >
          <div key='key' />
        </MultilineInput>
      ) : (
        <TextInput
          fullWidth
          InputProps={{ labelWidth: labelWidth }}
          InputLabelProps={{ shrink: true }}
          select
          error={error}
        >
          <div key='key' />
        </TextInput>
      )}
    >
      {placeholder ? <MenuItem disabled className={clsx([{ [classes.placeholder]: !localValue }])}>{placeholder}</MenuItem> : null}
      {options ? (
        options.map((option) => {
          return (
            <MenuItem
              key={option.value}
              value={option.value}
            >
              {option.label}
            </MenuItem>
          )
        })
      ) : null}
    </Select>
  )

  if (multiple) {
    return (
      <FormControl variant="outlined" style={style} fullWidth={fullWidth} className={clsx([classes.formControlMulti, className])}>
        <FormLabel required={required} className={classes.multiLabel}>{label}</FormLabel>
        <FormGroup className={`${classes.multiFormGroup} ${error ? classes.multiFormGroupError : ''}`}>
          {options ? options.map(option => (
            <FormControlLabel
              key={option.value}
              label={option.label}
              control={(
                <Checkbox
                  disabled={disabled}
                  color='secondary'
                  checked={option.selected || (value && value.findIndex(val => low(val) === low(option.value)) > -1)}
                  onChange={handleMultiChange}
                  value={option.value}
                  size='small'
                />
              )}
            />
          )) : null}
        </FormGroup>
        <FormHelperText className={classes.formHelperError} style={{display : error ? 'block' : 'none'}}>{error}</FormHelperText>
      </FormControl>
    );
  }

  return (
    <FormControl variant="outlined" margin={margin} fullWidth={fullWidth} className={clsx([className, classes.formControl])}>
      <InputLabel
        ref={inputLabel}
        // htmlFor={inputId}
        shrink
        required={required}
      >
        {label}
      </InputLabel>
      {select}
    </FormControl>
  );
}

export default SelectInput;
