import { makeStyles } from '@material-ui/core';
import Colors from '../../../theme/Colors';

const styles = {
  root: {
    position: "fixed",
    bottom: 20,
    right: 0,
    width: 316,
    margin: 8
  },
  fabContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end'
  },
  fab: {
    margin: 8,
    backgroundColor: Colors.primaryBlue,
    '&:hover': {
      backgroundColor: '#0c5fd7'
    }
  }
}

export default makeStyles(styles);
