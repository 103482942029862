import moment from 'moment-timezone'

function dateFormat(_date, format = 'MM-DD-YYYY', notSet = false) {
  let date;
  if (typeof _date === 'string') {
    if (_date.length === 10) {
      date = +(_date + '000');
    } else if (_date.length === 13) {
      date = +_date;
    }
  } else if (typeof _date === 'number') {
    if (_date < 10000000000) {
      date = _date * 1000
    }
  }

  date = date ? date : _date;

  const returnDate = ((date && typeof date === 'string') && date.includes('T') && notSet) ? moment(date).tz('America/Chicago').format(format) : moment(date).format(format);
  const inValidDates = ['31/12/1969', '01/01/1970', '12/31/1969'];
  if (notSet && (inValidDates.indexOf(new Date(returnDate).toLocaleDateString()) >= 0 || returnDate.toUpperCase() === 'INVALID DATE')) {
    return 'Not Set';
  } else {
    return returnDate;
  }
}

export function getFormattedDay(_date) {

  if (!_date || _date === 'Invalid Date') {
    return null;
  }

  const now = new Date();
  const date = new Date(_date).getTime();

  const todayEnd = moment(now).endOf('day').valueOf();
  const yesterdayStart = moment(now).add(-1, 'day').startOf('day').valueOf();
  const yesterdayEnd = moment(now).add(-1, 'day').endOf('day').valueOf();

  if (yesterdayEnd < date && date < todayEnd) {
    return 'Today'
  }
  if (yesterdayStart < date && date < yesterdayEnd) {
    return 'Yesterday'
  }

  return moment(_date).format('MMMM Do YYYY');
}



export default dateFormat;
