import {DialogContent, IconButton, Table, TableBody, TableCell, TableRow, Typography} from "@material-ui/core";
import {Delete} from "@material-ui/icons";
import React, {useState} from "react";
import FileUpload from "../biglist/BigListUpload/FileUpload";
import useStyles from "../../widgets/Confirmation/Confirmation.styles";

/**
 *
 * @param {*} props
 * Component to upload a member list via a csv file.
 */
function MemberListUpload(props) {
  const classes = useStyles();
  const [file, setFile] = useState([]);
  const [fileName, setFileName] = useState('');
  const {fileBrowser} = props;

  const handleFileSelect = (files) => {
    if (files) {
      setFile(files[0]);
      setFileName(files[0].name);
      fileBrowser.onChange(files);
    }
  }

  const handleFileRemoval = () => {
    setFile({});
    setFileName('');
    fileBrowser.onChange();
  }

  return (
    <DialogContent data-testid='memberlist-upload'>
      <FileUpload onChange={handleFileSelect} />
      <div>
        <Table>
          <TableRow>
            <TableCell>
              <Typography>File Name</Typography>
            </TableCell>
          </TableRow>

          { fileName && (
            <TableBody>
              <TableRow>
                <TableCell>
                  {fileName}
                </TableCell>
                <TableCell className={classes.buttonBorder} align='right' size='small'>
                  <IconButton
                    data-testid='remove'
                    onClick={handleFileRemoval}
                  >
                    <Delete />
                  </IconButton>
                </TableCell>
              </TableRow>
            </TableBody>
          )}
        </Table>
      </div>
    </DialogContent>
  );
}

export default React.memo(MemberListUpload);
