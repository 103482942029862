import { makeStyles } from '@material-ui/core';
import env from '../../../config/env';
import Colors from '../../../theme/Colors';
import { fontRegular } from '../../../theme/theme';
import { headerHeight } from '../../Common/styles';

const styles = {
  root: {
    display: 'flex',
    flexDirection: 'row',
    height: '100%',
    alignItems: 'center'
  },
  rootArrow: {
    color: `${env !== 'prod' ? Colors.white : Colors.primaryBlue}`
  },
  rootButton: {
    height: '100%',
    padding: '1.5em',
    paddingTop: 0,
    paddingBottom: 0,
    borderRadius: 0,
    transition: 'background-color 0.5s'
  },
  childButton: {
    width: 220,
    height: 100,
    paddingTop: 8,
    paddingBottom: 8,
    paddingRight: 16,
    paddingLeft: 16,
    borderRadius: 0,
    justifyContent: 'flex-start',
    transition: 'background-color 0.5s'
  },
  rootButtonLabel: {
  },
  buttonText: {
    fontWeight: fontRegular,
    color: Colors.grey500,
    textTransform: 'none'
  },
  buttonChildText: {
    fontWeight: fontRegular,
    color: Colors.grey900,
    textTransform: 'none',
    textAlign: 'left'
  },
  buttonChildDescriptionText: {
    fontWeight: fontRegular,
    color: Colors.grey300,
    textTransform: 'none',
    textAlign: 'left'
  },
  childrenRoot: {
    visibility: 'hidden',
    opacity: 0,
    display: 'flex',
    flexDirection: 'column',
    position: 'absolute',
    top: headerHeight,
    zIndex: 100,
    backgroundColor: Colors.white,
    // paddingBottom: 16,
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
    borderBottomLeftRadius: 6,
    borderBottomRightRadius: 6,
    transform: 'translateY(-8px)',
    transition: 'opacity 0.5s, transform 0.5s'
  },
  childrenRootRight: {
    visibility: 'hidden',
    opacity: 0,
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    left: 220,
    top: -100,
    zIndex: 100,
    transform: 'translateX(-8px)',
    transition: 'opacity 0.5s, transform 0.5s'
  },
  childrenRootLeft: {
    visibility: 'hidden',
    opacity: 0,
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    right: 220,
    top: -100,
    zIndex: 100,
    transform: 'translateX(8px)',
    transition: 'opacity 0.5s, transform 0.5s'
  },
  rootButtonContainer: {
    height: '100%',
    '&:hover > $childrenRoot': {
      visibility: 'visible',
      opacity: 1,
      transform: 'translateY(0px)'
    },
    '&:hover > a > $rootButton > $rootButtonLabel > $rootArrow': {
      transform: 'rotate(180deg)'
    }
  },
  childButtonContainer: {
    '&:hover > $childrenRootLeft': {
      visibility: 'visible',
      opacity: 1,
      transform: 'translateX(0px)'
    },
    '&:hover > $childrenRootRight': {
      visibility: 'visible',
      opacity: 1,
      transform: 'translateX(0px)'
    },
    '&:hover > a > $childButton > $rootButtonLabel > $rootArrow': {
      transform: 'rotate(180deg)'
    }
  },
  childContainer: {
    position: 'absolute',
    backgroundColor: Colors.white,
    // paddingBottom: 16,
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
    borderBottomLeftRadius: 6,
    borderBottomRightRadius: 6
  },
  menuButton: {
    marginRight: 16
  },
  drawer: {
    width: '100%',
    maxWidth: 360,
    top: '0 !important'
  },
  listItem: {
    width: '100%',
    maxWidth: 360
  },
  drawerHeader: {
    paddingTop: 20,
    backgroundColor: Colors.grey100,
    height: headerHeight,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  titleDrawer: {
    marginTop: -17,
    marginLeft: -8,
    fontSize: 27,
    color: Colors.logoBlue
  },
  logo: {
    marginRight: 16,
    height: 32
  },
  logoBlue: {
    color: Colors.logoBlue
  },
  logoWhite: {
    color: Colors.white
  },
  skeletonWrapper: {
    margin: 'auto',
    marginLeft: 8,
    height: '100%',
    display: 'flex',
    alignItems: 'center'
  },
  fullHeaderNav: {
    flex: 1,
    '& button': {
      position: 'absolute'
    },
    '& img': {
      margin: 'auto'
    }
  },
  sliderDetailsContainer: {
    padding: '1em',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'stretch',
    '& button': {
      width: '100%'
    },
    '& button > span': {
      justifyContent: 'flex-start'
    }
  },
  selectedRootDrawer: {
    '&>.MuiTypography-body1': {
      color: Colors.primaryBlue
    }
  }
}

export default makeStyles(styles);
