import React, { useState } from 'react';
import { Collapse, Fab } from '@material-ui/core';
import KeyboardArrowUp from '@material-ui/icons/KeyboardArrowUp';
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown';
import File from '../File/File';
import useStyles from './ProgressMenu.styles';
import ProgressCircle from '../../Progress/ProgressCircle';

function ProgressMenu(props) {
  const [show, setShow] = useState(false);
  const classes = useStyles();
  const { files, downloadFiles, removeFile, percent } = props;

  function toggleCollapse() {
    setShow(show => !show)
  }

  return (
    <div className={classes.root}>
      <div className={classes.fabContainer}>
        <Fab color="primary" className={classes.fab} onClick={toggleCollapse}>
          <ProgressCircle value={percent ? percent : 0} color='secondary' Icon={show ? KeyboardArrowUp : KeyboardArrowDown} size={50} />
        </Fab>
      </div>
      <Collapse in={show}>
        {files.map((fileObj) => <File key={fileObj.name} fileObj={fileObj} removeFile={removeFile} variant='circular' />)}
        {downloadFiles.map((fileObj) => <File key={fileObj.name} fileObj={fileObj} removeFile={removeFile} variant='circular' isDownload />)}
      </Collapse>
    </div>
  );
}

export default ProgressMenu;
