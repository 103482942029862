const styles = {
  background: {
    backgroundPosition: 'left top, right bottom',
    backgroundRepeat: 'no-repeat',
    backgroundSize: '25vw',
    minHeight: '100%',
    height: '100%'
  }
};

export default styles;
