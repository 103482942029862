import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { withRouter } from 'react-router';
import Button from "@material-ui/core/Button";
import { NavLink } from "react-router-dom";
import { styles } from "./styles";
import { UserDetailsPopover } from '../../widgets/user/user-details-popover';
import TopNavNoUser from './TopNavNoUser';

const TopNav = props => {
  const { classes, user, showRightNav, drawer } = props;

  if (!showRightNav && !drawer) {
    return null;
  }

  if (!user) {
    return <TopNavNoUser {...props} />
  }

  return (
    <div
      className={`${classes.topNavContainer} ${drawer ? classes.drawerMode : classes.topNavMode}`}
    >
      <NavLink to="/home" className={classes.navItems}>
        <Button>
          <span className={`icon-home ${classes.buttonIcon}`} />
          <span> Home</span>

        </Button>
      </NavLink>
      {drawer ? null : (
        <div className={classes.navItems}>
          <UserDetailsPopover {...props} />
        </div>
      )}
      <NavLink to="/admin" className={classes.navItems}>
        <Button>
          <span className={`icon-cog ${classes.buttonIcon} ${drawer ? '' : classes.noMargin}`} />
          {drawer ? 'Admin' : null}
        </Button>
      </NavLink>
    </div>
  );
};

export default withRouter(withStyles(styles)(TopNav));
