const styles = {
  /* Styles applied to the `Paper` component. */
  // We disable the focus ring for mouse, touch and keyboard users.
  content: {
    padding: "2px",
    display: "inline-block",
    marginLeft: "15px",
    paddingTop: "10px"
  },
  avatar: {
    display: "inline-block",
    verticalAlign: "top",
    marginTop: "20px",
    marginLeft: "15px"
  },
  cardBody: {
    minWidth: "320px"
  },
  vendorNames: {
    width: "250px",
    wordWrap: "break-word"
  }
};

export default styles
