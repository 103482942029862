import { useContext, useEffect } from 'react';
import { SessionContext } from '../../containers/persistence/SessionWrapper';

function useSetPersistence(persistKey, persistValue) {
  const sessionContext = useContext(SessionContext);

  useEffect(() => {
    return () => {
      if (sessionContext && sessionContext.removeSession) {
        sessionContext.removeSession(persistKey)
      }
    }
  }, [])

  useEffect(() => {
    if (sessionContext && sessionContext.setSession) {
      sessionContext.setSession(persistKey, persistValue)
    }
  }, [persistValue])
}

export default useSetPersistence;
