/*
    application insights service for exposing the intrumentation key
    creates an application insights instance
*/
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ReactPlugin , withAITracking } from '@microsoft/applicationinsights-react-js';
import { createBrowserHistory } from 'history';

const browserHistory = createBrowserHistory({ basename: '' });
const reactPlugin = new ReactPlugin();
const ai = new ApplicationInsights({
  config: {
    instrumentationKey: process.env.REACT_APP_INSTRUMENTATION_KEY, //process.env.PUBLIC_URL,
    extensions: [reactPlugin],
    extensionConfig: {
      [reactPlugin.identifier]: { history: browserHistory }
    },
    disableFetchTracking: false
  }
});

ai.loadAppInsights();

ReactPlugin.prototype.trackEvent = function(event, customProperties) {
  this._analyticsPlugin.trackEvent(event, customProperties)
}

export const appInsights = ai;
export { reactPlugin }
