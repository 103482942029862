import React, { PureComponent } from 'react';
import { withStyles } from '@material-ui/core';
import styles from './styles';

class FourOFour extends PureComponent {
  constructor(props) {
    super(props);
  }

  render() {
    const { classes } = this.props;

    return (
      <div className={classes.root}>
        404
      </div>
    );
  }
}

export default withStyles(styles)(FourOFour);
