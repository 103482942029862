import React from 'react';
import TextField from '@material-ui/core/TextField';
import clsx from 'clsx';
import useStyles from './MultilineInputStyles';

function MultilineInput(props) {
  const classes = useStyles();
  const { className, select, error, ...otherProps } = props

  return (
    <TextField
      {...otherProps}
      select={select}
      multiline={!select}
      className={clsx(classes.root, className)}
      InputLabelProps={{...props.InputLabelProps,
        classes: {
          shrink: classes.shrink,
          root: classes.cssLabel,
          focused: classes.cssFocused,
          error: classes.labelError
        },
        shrink: true
      }}
      InputProps={{...props.InputProps,
        classes: {
          root: clsx(classes.cssOutlinedInput, { [classes.selectInput]: select }),
          focused: classes.cssFocused,
          notchedOutline: classes.notchedOutline,
          input: classes.input,
          disabled: classes.outlineDisabled,
          error: classes.outlineError
        }
      }}
      FormHelperTextProps={{
        ...props.FormHelperTextProps,
        classes: {
          root: classes.formHelper,
          error: classes.formHelperError
        }
      }}
      variant="outlined"
      helperText={error}
      error={!!error}
    />
  );
}

export default MultilineInput;
