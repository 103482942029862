import { makeStyles } from "@material-ui/core/styles";
import Colors from "../../../theme/Colors";

export const useStyles = makeStyles(theme => ({

  stickyContainer: {
    padding: 0,
    margin: 0,
    position: "fixed",
    left: -360,
    top: 230,
    width: 400,
    height: 250,
    zIndex: 3
  },

  stickyLi: {
    position: "relative",
    listStyleType: "none",
    color: "#efefef",
    height: "auto",
    overflow: "hidden",
    padding: 0,
    borderTopRightRadius: 12,
    transition: "all 0.4s ease-in-out",
    webkitTransition: "all 0.4s ease-in-out",
    mozTransition: "all 0.4s ease-in-out",
    oTransition: "all 0.4s ease-in-out",
    cursor: "pointer",
    "&:hover": {
      marginRight: -330,
      paddingLeft: 170,
      backgroundColor: "#2484eae3",
      opacity: 0.95,
      boxShadow: "1px -1px 12px white"
    },
    border: "1px solid white"
  },

  stickyLiTitle: {
    position: "absolute",
    top: 75,
    right: -18,
    transform: 'rotate(-90deg)',
    fontSize: 16,
    fontFamily: 'Roboto',
    fontWeight: "bold",
    letterSpacing: 4
  },

  stickyLiTitleBack: {
    position: "absolute",
    top: 0,
    right: 0,
    backgroundColor: Colors.primaryBlue,
    width: 40,
    height: 200
  },

  stickyLiDescription: {
    position: "absolute",
    top: 25,
    right: 30,
    fontSize: 12,
    width: 210,
    fontFamily: 'Roboto'
  },

}));
