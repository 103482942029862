import React, { useState, createContext, useContext, useMemo, useCallback } from 'react';
import { useMutation } from '@apollo/react-hooks';
import { GET_ALL_BRANDING } from '../../queries/searchQueries';

export const BrandingContext = createContext({});
export const BrandingServiceContext = createContext({});
export const useBrandingContext = () => useContext(BrandingContext);
export const useBrandingServiceContext = () => useContext(BrandingServiceContext);

function BrandingProvider(props) {
  const [brandingList, setBrandingList] = useState(null);

  const [getBrandingService] = useMutation(GET_ALL_BRANDING, {
    onCompleted: handleOnCompleted
  })

  const getAllBranding = useCallback(() => {
    getBrandingService();
  }, [getBrandingService]);

  function handleOnCompleted(data) {
    if (data && data.searchNoIndex && data.searchNoIndex.payload) {
      const brandingList = data.searchNoIndex.payload;
      setBrandingList(brandingList);
    }
  }

  const brandingContext = useMemo(() => {
    return {
      brandingList
    }
  }, [brandingList])

  const brandingServiceContext = useMemo(() => {
    return {
      getAllBranding
    }
  }, [getAllBranding])

  const { children } = props;
  return (
    <BrandingContext.Provider
      value={brandingContext}
    >
      <BrandingServiceContext.Provider
        value={brandingServiceContext}
      >
        {children}
      </BrandingServiceContext.Provider>

    </BrandingContext.Provider>
  );
}

export default BrandingProvider;
