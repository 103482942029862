import React, { useState, useContext, useMemo } from "react";
import PropTypes from "prop-types";
import clsx from 'clsx';
import { useMediaQuery } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import { withRouter } from 'react-router-dom';
import '../../App.css';
import { styles } from './styles';
import TopNav from './TopNav';
import HeaderNavigation from '../header/HeaderNavigation/HeaderNavigation';
import UserComptContext from '../../setup/compartments/compartment.context';
import SimulateUserNotification from '../../widgets/admin/SimulateUserNotification';
import createPath from '../../Utils/navigation/createPath';
import stringCompare from '../../Utils/stringCompare';
import getCompartmentTypes from '../../Utils/compartments/getCompartmentTypes';
import { RoleIds, Roles } from '../../constants/Constants';
import env from "../../config/env";

function Navigations(props) {
  const [state, setState] = useState({
    showOverlay: false
  });

  const history = props.history;
  const { rootType } = props.match.params ? props.match.params : {};

  const {
    user: userFromContext,
    simulatedUser,
    simulatingUser,
    stopSimulateUser,
    simulatorCompartmentList
  } = useContext(UserComptContext);

  const noAccess = useMemo(() => {
    const currentUser = simulatingUser ? simulatedUser : userFromContext
    if (currentUser &&
      (currentUser.role &&
      (!currentUser.role.id || currentUser.role.id === RoleIds.UNASSIGNED || currentUser.role.name === Roles.UNASSIGNED)) ||
      (Object.keys(currentUser).length > 0 && !currentUser.role)) {
      return true;
    }
    return false;
  }, [userFromContext, simulatedUser, simulatingUser]);

  const drawer = useMediaQuery('(max-width:1024px)');
  const showRightNav = useMediaQuery('(min-width:768px)');

  function onClickStopSimulate() {
    stopSimulateUser();
    const adminCompartment = simulatorCompartmentList.filter(
      (compartment) =>
        stringCompare(compartment.type, getCompartmentTypes.USERS) && compartment.isRoot
    )[0];
    history.push(createPath(adminCompartment));
  }

  function setOverlay(val) {
    setState((state) => ({
      ...state,
      showOverlay: val
    }));
  }

  const { classes, user } = props;
  const commonProps = {
    user,
    showRightNav,
    rootType,
    setOverlay,
    noAccess
  };

  const home = !rootType || rootType === 'home';

  return (
    <div className={clsx([classes.root, { [classes.rootBanner]: simulatingUser }])}>
      <div
        className={clsx({
          [classes.overlay]: !home,
          [classes.visible]: state.showOverlay && !home
        })}
        onMouseMove={state.showOverlay ? () => setOverlay(false) : null}
      />
      <AppBar
        position="static"
        className={clsx({
          [classes.appBar]: true,
          [classes.appBarWhite]: !home && env === "prod",
          [classes.appBarBlue]: home
        })}
      >
        <Toolbar className={classes.toolbar} disableGutters>
          <div className={classes.topNav}>
            <HeaderNavigation drawer={drawer} {...commonProps} />
            <TopNav {...commonProps} />
          </div>
        </Toolbar>
      </AppBar>
      {simulatingUser ? (
        <SimulateUserNotification onClick={onClickStopSimulate} user={simulatedUser} />
      ) : null}
    </div>
  );
}

Navigations.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withRouter(withStyles(styles)(Navigations));
