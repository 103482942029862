import { baseURL, baseHeaders } from '../../constants/Endpoints';

async function createBiglistMetadataService(name, type, auto) {
  const body = {
    biglistName: name,
  };
  const response = await fetch(`${baseURL}biglist/${type}?auto=${auto ? 'true' : 'false'}`, {
    method: 'POST',
    body: JSON.stringify(body),
    headers: baseHeaders(),
  }).then(res => res.json()).then(data => data);

  return response;
}

export default createBiglistMetadataService;
