import React, { useState, useEffect } from 'react';

import { useMutation } from "@apollo/react-hooks";
import { setUserId } from '../../config/user';
import env from '../../config/env';
import GET_ALL_COMPARTMENTS from "../../queries/compartments-queries";
import { GET_USER } from '../../queries/user-queries';
import getCustomCompartments from '../../Utils/compartments/get-custom-compartments';
import UserComptContext from './compartment.context';
import getUserService from '../../services/admin/GetUserService';

const UserComptContextProvider = props => {
  const [compartmentList, setCompartmentList] = useState([]);
  const [simulatorCompartmentList, setSimulatorCompartmentList] = useState([]);
  const [userRole, setUserRole] = useState({});
  const [simulatedUser, setSimulatedUser] = useState(null);
  const simulatingUser = !!simulatedUser;

  useEffect(() => {
    if ((env != 'prod') && simulatedUser && simulatedUser.userId) {
      setUserId(simulatedUser.userId)
      return;
    }

    if (userRole && userRole.userId) {
      setUserId(userRole.userId)
    }
  }, [userRole, simulatedUser])

  // query user from service
  const [getUser, { data: dataUser }] = useMutation(GET_USER, {
    variables: {
      input: {}
    }
  });

  const [getAllCompartments, { data: comptData }] = useMutation(GET_ALL_COMPARTMENTS, {
    variables: {
      input: {}
    }
  });

  useEffect(() => {
    getData();
  }, [])

  useEffect(() => {
    handleOnCompleted(dataUser, comptData)
  }, [simulatedUser]);

  async function getData() {
    const [user, compartments] = await Promise.all([getUser(), getAllCompartments()]);

    if (user && user.data && compartments && compartments.data) {
      handleOnCompleted(user.data, compartments.data)
    }
  }

  function handleOnCompleted(dataUser, comptData) {
    loadCompartments(dataUser, comptData);
    loadUser(dataUser);
  }

  // load user to state
  function loadUser(dataUser) {
    if (dataUser && dataUser.user && dataUser.user.payload) {
      setUserRole(dataUser.user.payload)
    }
  }

  // load compartments to state
  function loadCompartments(dataUser, comptData) {
    if (dataUser && dataUser.user && comptData && comptData.compartments && comptData.compartments.payload) {
      const allCompartments = comptData.compartments.payload;
      let user;
      if (simulatedUser) {
        user = simulatedUser;
      } else {
        user = dataUser.user.payload;
      }
      /*
      * get filtered compartments for the signedIn / simulated user role
      */
      const customCompartments = getCustomCompartments(allCompartments, user.compartments);
      setCompartmentList(customCompartments);
      if (simulatedUser) {
        const originalCompartments = getCustomCompartments(allCompartments, dataUser.user.payload.compartments);
        setSimulatorCompartmentList(originalCompartments);
      }
    }
  }

  function simulateUser(user) {
    setSimulatedUser(user);
  }

  async function simulateUserId(userId) {
    const user = await getUserService(userId);
    setSimulatedUser(user);
  }

  function stopSimulateUser() {
    if (comptData && comptData.compartments && comptData.compartments.payload) {
      const allCompartments = comptData.compartments.payload;
      const customCompartments = getCustomCompartments(allCompartments, simulateUser.compartments);
      setCompartmentList(customCompartments);
      setSimulatedUser(null);
    }
  }

  return (
    <UserComptContext.Provider
      value={{
        compartments: compartmentList,
        user: userRole,
        simulateUser: simulateUser,
        stopSimulateUser: stopSimulateUser,
        simulatingUser: simulatingUser,
        simulatedUser: simulatedUser,
        simulateUserId: simulateUserId,
        simulatorCompartmentList: simulatorCompartmentList
      }}
    >
      {props.children}
    </UserComptContext.Provider>
  );
};

export default UserComptContextProvider;
