/*
  takes an object and a string array of keys
  returns value of obj base on path (array of keys)
*/
function getDeepValue(obj, arr) {
  if(arr && arr.length > 0) {
    if (!obj || typeof obj !== 'object') {
      return null;
    }
    const property = arr.shift();
    const newObject = obj[property];
    return getDeepValue(newObject, arr);
  }
  return obj;
}

export default getDeepValue;
