import React, { useState } from 'react';
import { ItemProps, SearchTypes, low} from '../../constants/Constants';
import SearchService from '../../setup/search/service.all';
import { ItemContext } from './ItemContext';

export const fetchCategories = (categories, setCategories) => async () => {
  if (categories.length > 0) {
    return categories;
  }
  const { '@search.facets': itemCategories } = await SearchService({
    filter: null,
    sort: null,
    type: low(SearchTypes.ITEM),
    additionalFilter: null,
    searchOptions: {
      "facets": ["catFull,count:200"]
    }
  });
  const catFull = itemCategories && itemCategories.catFull;
  const categoriesArray = processItemServiceRes(low(ItemProps.CAT), catFull);
  setCategories(categoriesArray);
  return categoriesArray;
}

const processItemServiceRes = (type, responseArr = []) => {
  const categoriesArray = responseArr.map((currentCatFull) => {
    const { value } = currentCatFull;
    if (value.indexOf('~') === -1) {
      return {}
    }

    const [id, label] = value.split('~');

    if (low(type) === low(ItemProps.CAT)) {
      return { value: id, label, childOptions: {subCategory: []} }
    } else {
      return { value: parseInt(id), label }
    }

  });
  categoriesArray.sort((item1, item2) => item1.value - item2.value);
  return categoriesArray;
}

export const fetchSubCategories = (categories, setCategories) => async (selectedCat) => {
  const { '@search.facets': itemCategories } = await SearchService({
    filter: null,
    sort: null,
    type: low(SearchTypes.ITEM),
    additionalFilter: null,
    searchOptions: {
      "facets": ["subcatFull,count:1000"],
      filter: `search.in(category, '${selectedCat}', ',')`
    }
  });
  const subCatFull = itemCategories.subcatFull;
  const subCategoriesArray = processItemServiceRes(low(ItemProps.SUBCAT), subCatFull);
  const catArr = [...categories];
  catArr.filter(category => category.value == selectedCat)[0].childOptions.subCategory = subCategoriesArray;
  setCategories(catArr);

}

export const ItemContextProvider = props => {
  const [categories, setCategories] = useState([]);

  const providerVal = {
    categories: categories,
    fetchCategories: fetchCategories(categories, setCategories),
    fetchSubCategories: fetchSubCategories(categories, setCategories)
  }
  return (
    <ItemContext.Provider value={providerVal}>
      {props.children}
    </ItemContext.Provider>
  )
}
