import { baseURL, baseHeaders } from '../../constants/Endpoints';

function processCouponCodes(couponCounter) {
  return couponCounter.map((i) => ({ ...i['suc'], id: i['suc'].couponCode,redemptionLimit:i.count,coupon_group: i['suc'].couponGroup })).sort((a, b) => new Date(b.expiryDate) - new Date(a.expiryDate))
}

function processAssignementData(item) {
  const hasValidResult = typeof item === 'object' && item !== null && (Object.keys(item).length === 0 || item.couponUsage)
  if (hasValidResult) {
    const isCouponPresent = item.couponUsage;
    return { "@odata.count": isCouponPresent ? item.couponUsage.length : 0, "value": isCouponPresent ?
      item.couponUsage.sort((a, b) => a.couponGroup.localeCompare(b.couponGroup)).reduce((allCoupons,couponGroup)=>{
        return [...allCoupons,...processCouponCodes(couponGroup.couponCounter)]
      },[]) : [] }
  } else if (item && item.errors) {
    const description = item.errors ? item.errors[0].description.split(':')[1] : "" ;
    return { "@odata.count": 0, "value": [], errorMessage: description }
  } else if (item && item.message) {
    return { "@odata.count": 0, "value": [], error: item.message }
  } else {
    return { error: true }
  }
}
async function getCouponService(body) {
  if (!body.search) {
    return processAssignementData({});
  }
  const membershipId = body.search.replace(/[(/.*.*/)]/g, '')
  try {
    return await fetch(`${baseURL}coupon/membershipId/${membershipId}`, {
      method: 'POST',
      body: JSON.stringify({}),
      headers: baseHeaders()
    }).then(function (res) {
      return res.json();
    }).then(function (data) {
      return processAssignementData(data);
    });
  } catch (error) {
    console.error(error)
    throw error;
  }
}
export default getCouponService;
