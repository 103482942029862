import Skeleton from "react-loading-skeleton";
import React from "react";
import { Grid } from "@material-ui/core";

const getSkeleton = (size, height=100) => {

  const newSkeleton = new Array(size).fill(0).map((_, i) => (
    <Grid item key={i} xs={12} md={6}>
      <Skeleton
        wrapper={({ children }) => <div>{children}</div>}
        height={height}
      />
    </Grid>
  ));
  return newSkeleton;
}
export default getSkeleton;
