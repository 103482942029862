import { createMuiTheme } from '@material-ui/core/styles';
import Colors from './Colors';

export const fontXLight = 100;
export const fontLight = 300;
export const fontRegular = 400;
export const fontMedium = 500;
export const fontBold = 700;
export const fontSizeXS = 10;
export const fontSizeSmall = 12;
export const fontSizeReg = 14;
export const fontSizeMed = 18;
export const fontSizeBig = 20;
export const iconSizeSmall = '1em';
export const iconSizeReg = '2em'
export const iconSizeMed = '3em';
export const iconSizeBig = '4em';

const fontColor = Colors.grey900;
const theme = createMuiTheme({
  props: {
    // Name of the component ⚛️
    MuiButtonBase: {
      // The default props to change
      disableRipple: true // No more ripple, on the whole application 💣!
    }
  },

  palette: {
    primary: {
      light: '#629749',
      main: Colors.lowlightGreen,
      dark: '#003d00',
      contrastText: Colors.white
    },
    secondary: {
      light: Colors.highlightBlue,
      main: Colors.primaryBlue,
      dark: '#0062cc',
      contrastText: Colors.white
    }
  },
  typography: {
    fontFamily: 'Roboto',
    fontSize: fontSizeReg,
    useNextVariants: true,
    h1: {
      fontWeight: fontLight,
      fontSize: 44,
      lineHeight: '50px',
      letterSpacing: -0.4,
      color: fontColor
    },
    h2: {
      fontWeight: fontLight,
      fontSize: 32,
      lineHeight: '36px',
      letterSpacing: -0.4,
      color: fontColor
    },
    h3: {
      fontWeight: fontLight,
      fontSize: 24,
      lineHeight: '28px',
      letterSpacing: -0.3,
      color: fontColor
    },
    h4: {
      fontWeight: fontLight,
      fontSize: 20,
      lineHeight: '22px',
      letterSpacing: 0,
      color: fontColor
    },
    h5: {
      fontWeight: fontBold,
      fontSize: 18,
      lineHeight: '20px',
      letterSpacing: 0,
      color: fontColor
    },
    h6: {
      fontWeight: fontBold,
      fontSize: fontSizeReg,
      lineHeight: '20px',
      letterSpacing: 0,
      color: fontColor
    },
    caption: {
      fontWeight: fontRegular,
      fontSize: 12,
      lineHeight: '17px',
      letterSpacing: 0,
      color: fontColor
    },
    body1: {
      fontWeight: fontRegular,
      fontSize: fontSizeReg,
      lineHeight: '24px',
      letterSpacing: 0,
      color: fontColor
    },
    body2: {
      fontWeight: fontRegular,
      fontSize: fontSizeReg,
      lineHeight: '21px',
      letterSpacing: 0,
      color: fontColor
    }
  },
  overrides: {
    MuiMenuItem: {
      root: {
        '&$selected': {
          backgroundColor: Colors.subtleBlue,
          color: Colors.primaryBlue
        }
      }
    },
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: Colors.primaryBlue
      }
    },
    MuiPickersCalendarHeader: {
      // switchHeader: {
      //   backgroundColor: 'red',
      //   color: "white"
      // }
    },
    MuiPickersDay: {
      day: {
        // color: Colors.subtleBlue
      },
      daySelected: {
        backgroundColor: Colors.primaryBlue,
        '&:hover': {
          backgroundColor: Colors.lowlightBlue
        }
      },
      dayDisabled: {
        // color: Colors.grey300
      },
      current: {
        color: Colors.primaryBlue
      }
    },
    MuiPickerDTTabs: {
      tabs: {
        backgroundColor: Colors.primaryBlue
      }
    },
    PrivateTabIndicator: {
      colorSecondary: {
        backgroundColor: Colors.highlightBlue
      }
    },
    MuiPickersClock: {
      pin: {
        backgroundColor: Colors.primaryBlue
      }
    },
    MuiPickersClockPointer: {
      pointer: {
        backgroundColor: Colors.primaryBlue
      },
      thumb: {
        borderColor: Colors.primaryBlue
      }
    },
    MuiPickersModal: {
      // dialogAction: {
      //   color: Colors.red
      // }
    },
    MuiTextField: {
      root: {
        borderRadius: '2px !important',
        '& legend': {
          width: '0px !important'
        }
      }
    },
    MuiDialogActions: {
      root: {
        '& > .MuiButton-textPrimary': {
          '&:hover': {
            backgroundColor: 'rgba(0, 0, 0, 0.08)'
          },
          color: Colors.primaryBlue
        }
      }
    },
    MuiBaseInput: {
      root: {
        fontFamily: 'Roboto',
        fontSize: fontSizeReg,
        lineHeight: 24,
        fontWeight: fontRegular,
        color: 'rgba(66, 66, 66, 0.4)'
      }
    },
    MuiInputBase: {
      root: {
        fontSize: fontSizeReg
      }
    },
    MuiInputLabel: {
      root: {
        fontFamily: 'Roboto',
        fontSize: 12,
        fontWeight: fontRegular,
        color: 'rgba(36, 37, 61, 0.5)',
        '&$focused': {
          color: 'rgba(36, 37, 61, 0.5)'
        },
        '&$shrink': {
          transform: 'translate(8px, -16px) !important'
        }
      }
    },
    MuiButton: {
      label: {
        textTransform: 'capitalize'
      }
    },
    MuiChip: {
      label: {
        fontSize: fontSizeReg
      }
    },
    MuiListItem: {
      // checkbox: {
      //   paddingRight: 1
      // }
    },
    MuiListItemIcon: {
      root:{
        minWidth: 0
      }
    },
    MuiIconButton: {
      root: {
        fontSize: iconSizeSmall
      }
    },
    PrivateSwitchBase: {
      root: {
        '& .MuiSvgIcon-root': {
          fontSize: fontSizeReg
        }
      }
    }
    // MuiSvgIcon: {
    //   root:{
    //     height: '4em',
    //     width: '4em'
    //   }

    // }
  }
});

export default theme;
