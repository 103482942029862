import { baseURL, baseHeaders } from '../../constants/Endpoints';

/**
 * Updates the metadata of biglist's downloads object with
 * with REQUESTED status for the given user.
 * @param {name} biglistName
 */
async function prepareDownloadService(biglistName, type='biglist') {
  const url = `${baseURL}files/${type}/${biglistName}/download`;
  await fetch(url, {
    method: 'POST',
    headers: baseHeaders()
  }).then(function (res) {
    return res.text();
  }).then(function (data) {
    return data;
  });
}

export default prepareDownloadService
