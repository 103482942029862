import { makeStyles } from '@material-ui/core';
import Colors from '../../theme/Colors'
import { fontRegular, fontLight, fontBold, fontMedium } from '../../theme/theme';

const styles = theme => ({
  root: {
    zIndex: 100,
    position: 'absolute',
    backgroundColor: '#000000bb',
    height: '100%',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column'
  },
  circleMainRoot: {
  },
  circleMain: {

  },
  circleMainColor: {
    color: Colors.highlightBlue
  },
  circleSecondaryMainColor: {
    color: Colors.white
  },
  circleSvg: {
    strokeLinecap: 'round'
  },
  text: {
    marginTop: 32,
    textAlign: 'center',
    fontSize: 24,
    color: Colors.white
  }
})

export default makeStyles(styles);
