import { notAvailable } from '../constants/Constants';

function getUserName(currentUser) {
  if(currentUser && currentUser.userInfo) {
    return currentUser.userInfo.name || currentUser.userInfo.userName || notAvailable;
  }
  return notAvailable;
}

export default getUserName;
