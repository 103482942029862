import { useState } from 'react';
import { useLoadingDispatchContext, addLoad, removeLoad } from '../containers/loading/AppLoading';

function useLoadingService(serviceFunc, {
  onCompleted,
  onError,
  appLoadingId, // set this if you want to bring up the app loading screen
} = {}) {
  const loadingDispatch = useLoadingDispatchContext()
  const [loading, setLoading] = useState(false);

  async function call(...params) {
    try {
      if (appLoadingId) {
        loadingDispatch(addLoad({ id: appLoadingId }))
      }
      setLoading(true);
      const response = await serviceFunc(...params);
      if (response && (response.error || (response.errors && response.errors.length > 0) || response.status >= 400 || response.statusCode >= 400) || response instanceof Error) {
        if (onError) {
          await onError(response);
        }
        if (!response.error) {
          response.error = true;
        }
      } else {
        if (onCompleted) {
          await onCompleted({ payload: response });
        }
      }
      if (appLoadingId) {
        loadingDispatch(removeLoad(appLoadingId))
      }
      setLoading(false);

      return response;
    } catch (error) {
      if (onError) {
        await onError({ error: error });
      }
      if (appLoadingId) {
        loadingDispatch(removeLoad(appLoadingId))
      }
      setLoading(false);
      return error;
    }
  }

  return [call, { loading }];
}

export default useLoadingService;
