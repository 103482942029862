import React, { createContext, useContext, useReducer } from 'react';

export const ProcessingContext = createContext([]);
export const ProcessingDispatchContext = createContext(() => {});
export const useProcessingContext = () => useContext(ProcessingContext);
export const useProcessingDispatchContext = () => useContext(ProcessingDispatchContext);
const ProcessingAddAction = 'processingAddAction';
const ProcessingAddIfNotExistAction = 'processingAddIfNotExistAction';
const ProcessingEditAction = 'processingEditAction';
const ProcessingRemoveAction = 'processingRemoveAction';

export function addProcess(process) {
  return {
    type: ProcessingAddAction,
    process
  }
}

export function addProcessIfNotExist(process) {
  return {
    type: ProcessingAddIfNotExistAction,
    process
  }
}

export function editProcess(process) {
  return {
    type: ProcessingEditAction,
    process
  }
}

export function removeProcess(id) {
  return {
    type: ProcessingRemoveAction,
    id
  }
}

const initialState = { processes: [] };

function processingReducer(state, action) {
  const index = state.processes.findIndex((process) => process.id === (action.id || (action.process && action.process.id)));
  switch (action.type) {
    case ProcessingAddIfNotExistAction:
      if (index >= 0) {
        return state;
      }
    // eslint-disable-next-line no-fallthrough
    case ProcessingAddAction:
      return { processes: state.processes.concat([action.process]) };
    case ProcessingEditAction:
      return { processes: [...state.processes][index] = { ...[state.processes][index], ...action.process } };
    case ProcessingRemoveAction:
      if (index < 0) {
        return state;
      }
      return { processes: state.processes.slice(0, index).concat(state.processes.slice(index+1)) };
  }
}

function BackgroundProcessingProvider(props) {
  const [state, dispatch] = useReducer(processingReducer, initialState);
  const { children } = props;
  return (
    <ProcessingContext.Provider
      value={state.processes}
    >
      <ProcessingDispatchContext.Provider
        value={dispatch}
      >
        {children}
      </ProcessingDispatchContext.Provider>
    </ProcessingContext.Provider>
  );
}

export default BackgroundProcessingProvider;
