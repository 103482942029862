import React from "react";
import { withRouter } from "react-router";
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import Tooltip from '@material-ui/core/Tooltip';
import { NavLink } from "react-router-dom";

const QuestionMark = () => {
  return (
    <NavLink
      to="/tutorials"
      style={{
        marginLeft: 4,
        marginRight: 4,
        height: 24,
        color: 'inherit'
      }}
    >
      <Tooltip arrow title="Tutorials">
        <HelpOutlineIcon
          style={{cursor: "pointer"}}
        />
      </Tooltip>
    </NavLink>
  );
};

export default withRouter(QuestionMark);
