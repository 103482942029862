import { baseURL, baseHeaders } from '../../constants/Endpoints';

/**
 *
 * @param {string} biglistName - name of biglist
 * @return - metadata for the biglist with the given name.
 */
async function getBiglistService(biglistName, type='biglist') {
  try {
    const response = await fetch(`${baseURL}files/${type}/${biglistName}`, {
      method: 'GET',
      headers: baseHeaders()
    }).then(function (res) {
      return res.json();
    }).then(function (data) {
      return data;
    });

    return response;
  } catch (error) {
    return error;
  }
}

export default getBiglistService
