import React, { createContext, useContext, useReducer } from 'react';
import FadeLoader from 'react-spinners/FadeLoader';
import useStyles from './AppLoading.styles';
import Colors from '../../theme/Colors'

export const LoadingContext = createContext([]);
export const LoadingDispatchContext = createContext(() => {});
export const useLoadingContext = () => useContext(LoadingContext);
export const useLoadingDispatchContext = () => useContext(LoadingDispatchContext);
const LoadingAddAction = 'loadingAddAction';
const LoadingRemoveAction = 'loadingRemoveAction';

export function addLoad(load) {
  return {
    type: LoadingAddAction,
    load
  }
}

export function removeLoad(id) {
  return {
    type: LoadingRemoveAction,
    id
  }
}

const initialState = { loads: [] };

function loadingReducer(state, action) {
  const index = state.loads.findIndex((load) => load.id === (action.id || (action.load && action.load.id)));
  switch (action.type) {
    case LoadingAddAction:
      if (index >= 0) {
        return state;
      }
      return { loads: state.loads.concat([action.load]) };
    case LoadingRemoveAction:
      if (index < 0) {
        return state;
      }
      return { loads: state.loads.slice(0, index).concat(state.loads.slice(index+1)) };
  }
}

function AppLoading(props) {
  const classes = useStyles();
  const [state, dispatch] = useReducer(loadingReducer, initialState);
  const { children } = props;
  return (
    <LoadingContext.Provider
      value={state.loads}
    >
      <LoadingDispatchContext.Provider
        value={dispatch}
      >
        {children}
        {state.loads.length > 0 ? (
          <div className={classes.root}>
            <FadeLoader
              color={Colors.highlightBlue}
              height={64}
              margin={36}
              width={10}
              radius={8}
              cssOverride={{
                top: 0
              }}
            />
          </div>
        ) : null}
      </LoadingDispatchContext.Provider>
    </LoadingContext.Provider>
  );
}

export default AppLoading;
