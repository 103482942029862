/* eslint-disable import/prefer-default-export */
import gql from "graphql-tag";

const SEARCH = gql`
mutation($type: String!, $index: String!) {
  search(type: $type, index: $index, input: $input) @rest(type: "search", path: "index/{args.index}", method: "POST",endpoint: "search") {
    value
  }
}`;


const SEARCH_NO_INDEX = gql`
mutation searchNoIndex($rootType: String!){
  searchNoIndex(input: $input, rootType: $rootType) @rest(type: "searchNoIndex", path: "{args.rootType}/search", method: "POST") {
    status,
    header, 
    errors,
    payload
  }
}`;

const GET_ALL_BRANDING = gql`
mutation getAllBranding($rootType: String!){
  searchNoIndex(input: $input, rootType: $rootType) @rest(type: "branding", path: "branding/all", method: "GET") {
    status,
    header, 
    errors,
    payload
  }
}`;

const SEARCH_ROLES = gql`
mutation searchNoIndex {
  searchNoIndex @rest(type: "searchNoIndex", path: "users/roles", method: "GET") {
    status,
    header, 
    errors,
    payload
  }
}`;

const SEARCH_SERVICE = gql`
mutation searchCoupon($rootType: String!){
  searchNoIndex(input: $input, rootType: $rootType) @rest(type: "searchNoIndex", path: "search?searchType={args.rootType}", method: "POST") {
    status,
    header, 
    errors,
    payload
  }
}`;

export {
  SEARCH,
  SEARCH_NO_INDEX,
  SEARCH_SERVICE,
  SEARCH_ROLES,
  GET_ALL_BRANDING
}
