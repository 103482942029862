import React, { useState, useContext, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Skeleton from 'react-loading-skeleton';
import { Card, CardContent, Button } from '@material-ui/core';
import TablePagination from '@material-ui/core/TablePagination';
import useStyles from './CardNavStyles';
import UserComptContext from '../../setup/compartments/compartment.context';
import getInsightsEvents from '../../setup/application-insights/events.service';

const RecentsCard = (
  props
) => {

  const classes = useStyles();
  const [curPage, setCurPage] = useState(0);
  const [recentsNav, setRecentsNav] = useState([]);
  const [loading, setLoading] = useState(false);
  const ITEMS_PER_PAGE = 3;

  const userFromContext = useContext(UserComptContext).user;
  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const resource = await getInsightsEvents(userFromContext ? userFromContext.id : null);
        setRecentsNav(getUniqueListByName(resource.value));
        setLoading(false);
      } catch (error) {
        console.log('getInsightsEvents request failed:', error);
      }
    };
    fetchData();

  }, [userFromContext]);

  function getUniqueListByName(arr) {
    return [...new Map(arr.map(item => [item.customDimensions.name, item])).values()]
  }

  const renderRecentPageItem = ({customDimensions, id}) => {
    return (
      <Link key={id} to={customDimensions.location} className={classes.childLinks}>
        <Button size='small' color='secondary' fullWidth>
          {customDimensions.name}
        </Button>
      </Link>
    );
  }

  return (
    <Card classes={{}} className={classes.recentCardContainer} style={props.styles}>
      <CardContent className={classes.recentsCardContentContainer} style={{minHeight: 250, position: "relative"}}>
        <div className={classes.recentsNav}>
          {
            props.title? (
              <div style={{width: "100%", textAlign: "center", marginTop: 15, marginBottom: 20}}>{props.title}</div>
            ) : null

          }
          {
            loading ? (
              <Skeleton height={40} count={ITEMS_PER_PAGE} />
            ) : recentsNav ? (
              recentsNav.filter(
                (e, index) => {
                  return (index >= ITEMS_PER_PAGE * curPage) && (index < ITEMS_PER_PAGE * (curPage + 1))
                }
              ).map(renderRecentPageItem)
            ) : 'No recent pages'
          }
        </div>
        <div className={classes.paginationHolder}>
          {recentsNav?
            (
              <TablePagination
                component="div"
                count={recentsNav.length}
                rowsPerPage={ITEMS_PER_PAGE}
                rowsPerPageOptions={[ITEMS_PER_PAGE]}
                page={curPage}
                onChangePage={(event, value) => setCurPage(value)}
                labelRowsPerPage=""
              />
            )
            : null
          }
        </div>
      </CardContent>
    </Card>
  );
}

export default RecentsCard;
