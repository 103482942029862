import { makeStyles } from '@material-ui/core';
import Colors from '../../theme/Colors';

const styles = () => ({
  root: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: Colors.subtleBlue,
    paddingLeft: 16,
    paddingRight: 16,
    boxSizing: 'border-box',
    height: 48
  },
  leftContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  icon: {
    color: Colors.primaryBlue,
    fontSize: 26
  },
  button: {

  },
  text: {
    // fontWeight: fontMedium
    marginLeft: 16
  }
});

export default makeStyles(styles);
