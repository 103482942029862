import { makeStyles } from '@material-ui/core';
import Colors from '../../theme/Colors';
import { fontRegular } from '../../theme/theme';

const styles = {
  root: {

  },
  formControl: {
  },
  formControlMulti: {
  },
  icon: {
    zIndex: 1,
    color: Colors.primaryBlue
  },
  selectRoot: {
    paddingRight: 32
  },
  cssLabel: {
    '&$cssFocused': {
      color: Colors.primaryBlue
      // transform: 'translate(12px, -6px) scale(0.75)'
    },
    '&$labelError': {
      color: Colors.primaryOrange
    },
    '&$shrink': {
      // transform: 'translate(12px, -6px) scale(0.75)'
    },
    // left: 8,
    color: Colors.grey500,
    transform: 'translate(14px, 12.5px) scale(1)',
    fontWeight: fontRegular
  },
  shrink: {},
  cssFocused: {
    color: Colors.grey900
  },
  labelError: {},
  placeholder: {
    backgroundColor: 'rgba(0, 0, 0, 0.14) !important',
    color: `${Colors.black} !important`
  },
  select: {
    '&:focus': {
      backgroundColor: 'transparent'
    }
  },
  multiSelectRoot: {
    height: '100%'
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  placeholdText: {
    color: Colors.grey300
  },
  multiFormGroup: {
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'row',
    '& .MuiCheckbox-root': {
      color: Colors.primaryBlue
    }
  },
  multiFormGroupError: {
    '& .MuiCheckbox-root': {
      color: Colors.red
    }
  },
  iconDisabled: {
    color: Colors.grey300
  },
  multiLabel: {
    fontSize: 12,
    color: 'rgba(36, 37, 61, 0.5)'
  },
  formHelperError: {
    color: '#f44336',
    margin: '-3px 0px'
  }
};

export default makeStyles(styles);
