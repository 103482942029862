import { makeStyles } from "@material-ui/core/styles";
import Colors from '../../theme/Colors';

const useStyles = makeStyles(theme => ({
  cardContainer: {
    height: '50%',
    width: '80%',
    display: 'flex',
    border: '1px solid transparent',
    '&:hover': {
      border: '1px solid #0071E9',
      boxShadow: '0px 4px 8px 0px rgba(0,0,0,0.2)'
    },
    '& button': {
      textTransform: 'capitalize'
    },
    '& > a': {
      flex: 1
    }
  },
  recentCardContainer: {
    display: 'flex',
    border: '1px solid transparent',
    '&:hover': {
      border: '1px solid #0071E9',
      boxShadow: '0px 4px 8px 0px rgba(0,0,0,0.2)'
    },
    '& button': {
      textTransform: 'capitalize'
    },
    '& > a': {
      flex: 1
    },
    // position: 'absolute',
    right: 40,
    width: 330,
    height: 194,
    marginTop: 2,
    borderRadius: 0
  },
  image: {
    height: '50%',
    width: '40%'
  },
  details: {
    display: 'flex',
    flex:3,
    flexDirection: 'column'
  },
  details1: {
    display: 'flex',
    flex:3,
    flexDirection: 'column'
  },
  paper:{
    alignItems: 'center',
    height: 100,
    display: 'flex',
    position: 'relative'

  },
  cardContentContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    height: '200px',
    width: '100%',
    boxSizing: 'border-box'
  },
  recentsCardContentContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: "100%",
    boxSizing: 'border-box',
    paddingTop: 0
  },
  nameContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  mainButton: {
    alignItems: 'center',
    marginTop: 8,
    marginLeft: 'auto',
    marginRight: 'auto',
    height: '40px',
    width: 300,
    maxWidth: '100%'
  },
  getStartedButton: {
    backgroundColor:"#008200",
    color: '#ffffff'
  },
  openButton: {
    // borderColor: "#008200",
    // color: '#008200'
    backgroundColor:"#008200",
    color: '#ffffff',
    height: 40
  },
  dividerShort: {
    borderRadius: '2px',
    backgroundColor: Colors.grey200,
    width:'2px',
    height: '50px',
    margin: 'auto'
  },
  dividerLong: {
    borderRadius: '2px',
    backgroundColor: Colors.grey200,
    width:'2px',
    height: '120px',
    margin: 'auto'
  },
  getStartedIcon: {
    marginLeft: 8
  },
  cardName: {
    fontWeight: '500'
  },
  optionText: {
    fontWeight: '500',
    marginTop: '2px',
    margin: 8
  },
  recentsNavWrapper: {
  },
  recentsNavContainer: {
    boxSizing: 'border-box',
    padding: 8
  },
  recentsNav: {
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap',
    width: '100%'
  },
  recentPagesHeader: {
    fontWeight: '500',
    fontSize: 14,
    padding: 8,
    paddingTop: 0
  },
  childNav: {
    display: 'flex',
    // flexDirection: 'column',
    flexWrap: 'wrap',
    marginTop: 8
  },
  fullWidth: {
    width: '100%'
  },
  childLinks: {
    flexGrow: 1
  },
  childLinkMargin: {
    margin: '0px 0px'
  },
  description: {
    marginBottom: 'auto'
  },
  paginationHolder: {
    position: "absolute",
    bottom: 0
  }
}));

export default useStyles;
