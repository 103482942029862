import React from 'react';
import { render } from 'react-dom';

import { ApolloClient, InMemoryCache, ApolloLink } from 'apollo-boost';
import { ApolloProvider } from '@apollo/react-hooks';
import { RestLink } from 'apollo-link-rest';

import registerServiceWorker from './registerServiceWorker';
import './index.css';
import App from './App';
import { baseURL, localURL, baseHeaders } from './constants/Endpoints'
import { AppInsightsContextProvider } from './setup/application-insights/app-insights.context';

const authRestLink = new ApolloLink((operation, forward) => {
  operation.setContext(({ headers }) => {
    if (!headers) {
      return { headers: baseHeaders() }
    }
    return { headers };
  });
  return forward(operation).map(result => {
    return result;
  })
});

async function responseTransformerPayload(res) {
  if (res && res.json) {
    return res.json().then((data) => {
      if (data) {
        if (data.errors) {
          return data;
        }
        return { payload: data };
      }
      return null
    })
  }

  return null
}

async function responseTransformerDefault(res) {
  if (res && res.json) {
    return res.json().then((data) => {
      return data
    })
  }

  return null;
}

//With two endpoint
const restLink = new RestLink({
  uri: baseURL,
  responseTransformer: responseTransformerPayload,
  endpoints: {
    base: baseURL,
    local: localURL,
    search: {
      uri: baseURL,
      responseTransformer: responseTransformerDefault
    }
  }
});

// setup your client
const client = new ApolloClient({
  link: ApolloLink.from([authRestLink, restLink]),
  cache: new InMemoryCache(),
  resolvers: {},
  connectToDevTools: true,
  typeDefs: {}
});

render(
  <ApolloProvider client={client}>
    <AppInsightsContextProvider>
      <App />
    </AppInsightsContextProvider>
  </ApolloProvider>,
  document.getElementById("root")
);

if (module.hot) {
  module.hot.accept();
}

registerServiceWorker();
