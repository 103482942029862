import Colors from '../../theme/Colors'

const styles = {
  backToHomeButton: {
    textTransform: "none",
    border: "1px solid " + Colors.primaryBlue,
    backgroundColor: Colors.white,
    color: Colors.primaryBlue,
    padding: '10px 30px',
    marginTop: '25px'
  },
  errorContainer: {
    maxWidth: '1024px',
    margin: 'auto',
    boxSizing: 'border-box',
    padding: '50px',
    width: '100%',
    background: Colors.white,
    borderRadius: '5px'
  },
  errorWrapper: {
    background: '#f5f6f8',
    position: 'relative',
    height: '100vh'
  }
}

export default styles;
