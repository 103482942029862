import { Base64 } from 'js-base64';
import { Util } from '@microsoft/applicationinsights-web';
import { appInsights } from '../setup/application-insights/app-insights.service';
import { getUserId } from '../config/user'
import env from '../config/env';

const jsonContentType='application/json';
export const account = process.env.REACT_APP_STORAGE_ACCOUNT;
export const tokenSas = 'token/sas';
export const containerName = 'promosetup';
export const baseFolder = '';
export const baseFolderDownload = 'download/';

export const baseURL = process.env.REACT_APP_BASE_URL
export const baseKey = process.env.REACT_APP_BASE_SUBSCRIPTION_KEY
export const ssoURL = process.env.REACT_APP_SSO_URL
export const ssoBaseURL = process.env.REACT_APP_SSO_BASE_URL
export const localURL = 'http://localhost:3001/'
export const mrnURL = process.env.REACT_APP_MRN_URL

export const itemImageUrl = upc => `https://scene7.samsclub.com/is/image/samsclub/${upc}_A`

export const baseHeaders = getHeaders;

export const Index = {
  CAMPAIGN: process.env.REACT_APP_INDEX_CAMPAIGN,
  PROMOTION: process.env.REACT_APP_INDEX_PROMOTION,
  ITEMS: process.env.REACT_APP_INDEX_ITEMS,
  BIGLIST: process.env.REACT_APP_INDEX_BIGLIST,
  COUPON: process.env.REACT_APP_INDEX_COUPON,
  USER: process.env.REACT_APP_INDEX_USER,
  COUPONCODE: process.env.REACT_APP_INDEX_COUPON_CODE || "qs-multicoupongroup-index",
  TERMSANDCONDITIONS: process.env.REACT_APP_INDEX_TERMSANDCONDITIONS,
  CLUB: process.env.REACT_APP_INDEX_CLUB,
  ACTION: process.env.REACT_APP_INDEX_ACTION,
  OFFERSET: process.env.REACT_APP_INDEX_OFFERSET,
  LABEL: process.env.REACT_APP_INDEX_LABEL,
  EMAILTEMPLATE: process.env.REACT_APP_INDEX_EMAIL_TEMPLATE,
  PROMOTIONGROUPS: process.env.REACT_APP_INDEX_PROMOTIONGROUPS,
  GENERICTARGETEDMESSAGE:process.env.REACT_APP_INDEX_TARGETEDMESSAGE,
  GTMSLOT:process.env.REACT_APP_INDEX_GTMSLOT
}

async function customFetch(url, options, ...args) {
  let corsOptions;

  const isBaseUrl = url.includes(baseURL);
  if (isBaseUrl) {
    corsOptions = {
      ...options,
      credentials: 'include'
      // crossDomain: true
    }
  } else {
    corsOptions = options;
  }

  const requestStart = Date.now();
  const fetchResponse = await global.originalFetch(url, corsOptions, ...args);
  const requestEnd = Date.now();

  const response = fetchResponse.clone();

  if (response && response.status === 403 && isBaseUrl) {
    const body = await response.text();
    if (body === 'Access Denied' && ssoURL) {
      if (window.persistSession) {
        window.persistSession();
      }
      window.location.href = ssoURL
    }
  } else if (response && response.status >= 400) {
    const body = response.text();
    const traceID = Util.generateW3CId();
    const id = "quest" + traceID;
    appInsights.trackDependencyData({
      name: `${options.method} ${url}`,
      id,
      responseCode: response.status,
      absoluteUrl: url,
      success: response.ok,
      duration: requestEnd - requestStart,
      method: options.method,
      properties: {
        requestBody: options.body ? options.body : '',
        responseBody: body ? body : ''
      },
      type: 'Fetch'
    })
  }

  if (isBaseUrl) {
    if (window.updateSessionTimeout) {
      // show modal after 55 minutes
      window.updateSessionTimeout(55 * 60 * 1000);
    }
  }

  return fetchResponse;
}
if (!global.originalFetch) {
  global.originalFetch = global.fetch;
}
if (env === 'prod' || env === 'stage' || env === 'dev') {
  global.fetch = customFetch;
}

export function getHeaders() {
  if(window.fetch) {
    const headers = new Headers();
    const key = process.env.REACT_APP_BASE_API_KEY;
    headers.set('Content-Type',jsonContentType);
    headers.set('Ocp-Apim-Subscription-Key', process.env.REACT_APP_BASE_SUBSCRIPTION_KEY);
    headers.set('api-key', key ? Base64.decode(key) : '');
    headers.set('SAMS-CONSUMER-USERID', getUserId());
    return headers
  } else {
    return {}
  }
}
