import React, { useContext } from 'react';
import { withRouter } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { Box, Container } from '@material-ui/core';
import LocalOfferIcon from '@material-ui/icons/LocalOffer';
import PollIcon from '@material-ui/icons/Poll';
import HelpIcon from '@material-ui/icons/Help';
import ListAltIcon from '@material-ui/icons/ListAlt';
import ConfirmationNumberIcon from '@material-ui/icons/ConfirmationNumber';
import EventNoteIcon from '@material-ui/icons/EventNote';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import Button from '@material-ui/core/Button';
import { fontSizeMed, iconSizeMed, fontSizeReg } from '../../theme/theme';
import { Roles } from '../../constants/Constants';
import UserComptContext from '../../setup/compartments/compartment.context';
import getStepLabel from '../../Utils/workflow/get-step-label';
import Colors from '../../theme/Colors'
import createPath from '../../Utils/navigation/createPath';

const useStyles = makeStyles(theme => ({
  menuBlock: {
    padding: 10
  },
  buttonContainer: {
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
    backgroundColor: Colors.white,
    paddingLeft: 16,
    paddingRight: 0,
    borderBottom: "1px solid rgba(158, 158, 158, 0.5)"
  },
  buttonHeader: {
    paddingTop: 10,
    paddingBottom: 10
  },
  headerRow: {
    display: "flex",
    flexFlow: "row wrap",
    alignItems: "center",
    height: 48
  },
  headerBoxIcon: {
    alignContent: "flex-start",
    cursor: "pointer"
  },
  headerBoxTitle: {
    marginLeft: 8
  },
  headerBoxTitleText: {
    cursor: "pointer",
    color: "#6a7187",
    fontWeight: "bold",
    fontSize: fontSizeMed
  },
  descriptionContainer: {
    display: "flex",
    flexDirection: "column",
    borderBottomLeftRadius: 8,
    borderBottomRightRadius: 8,
    backgroundColor: Colors.white,
    paddingTop: 20,
    paddingLeft: 16,
    paddingRight: 16,
    height: 164
  },
  descriptionContainerTypography: {
    height: 64,
    fontSize: fontSizeReg,
    color: Colors.grey300,
    fontWeight: "bold"
  },
  descriptionContainerBox: {
    marginTop: 17
  },
  itemButton: {
    minWidth: 120,
    textTransform: "none",
    border: "1px solid " + Colors.primaryBlue,
    marginRight: 12,
    marginBottom: 12

  },
  itemButtonWhite: {
    backgroundColor: Colors.white,
    color: Colors.primaryBlue
  },
  iconButton: {
    color: Colors.primaryBlue,
    fontSize: iconSizeMed
  }
}));


function NaviButton(props) {

  const classes = useStyles();

  const renderBlockIcon = (param) => {

    switch(param) {
      case 'Campaigns':
        return <PollIcon className={classes.iconButton} />;
      case 'Promotions':
        return <LocalOfferIcon className={classes.iconButton} />;
      case 'Coupons':
        return <ConfirmationNumberIcon className={classes.iconButton} />;
      case 'Big Lists':
        return <ListAltIcon className={classes.iconButton} />;
      case 'Event Sales Branding':
        return <EventNoteIcon className={classes.iconButton} />;
      case 'Admin':
        return <SupervisorAccountIcon className={classes.iconButton} />;
      case 'Plus Free Shipping Override':
        return <HelpIcon className={classes.iconButton} />;
      default:
        return <PollIcon className={classes.iconButton} />;
    }
  }
  const { user, simulatedUser, simulatingUser } = useContext(UserComptContext);
  const userDetails = simulatingUser ? simulatedUser : user;
  const role = userDetails.role && userDetails.role.name;
  const getUrlPath = (p) => {

    let path = "";
    if (p.childComp){
      const parentPath = createPath(p.comp);
      path = createPath(p.childComp, p.comp, p.comp.type, parentPath);
    }else{
      path = createPath(p.comp);
    }

    return path;
  };

  const handleTransfer = (indexCompartment, indexButton) => {
    if (typeof indexButton != "undefined") {
      const childComp = (props.data[indexCompartment].childrenData) ? props.data[indexCompartment].childrenData[indexButton]: null;
      forwardToUrl({comp: props.data[indexCompartment], childComp: childComp});
    }
  };

  const forwardToUrl = (p)  => {
    const path = getUrlPath(p);
    props.history.push(path);
  };

  const capitalize = (s) => {
    if (typeof s !== 'string') {
      return ''
    }
    return s.charAt(0).toUpperCase() + s.slice(1)
  }

  let threeInRowIndex = -1;
  let nextStartsThreeInRow = false;
  let lastRowClosed = true;

  const renderPossibleActions = (classes, indexButton, index, option) => {
    const { itemButton, itemButtonWhite } = classes;
    const userRole = userDetails.role && userDetails.role.name;
    if (userRole && userRole.includes(Roles.READONLY) && option && option.name.includes('Create')) {
      return '';
    }
    if(option){
      return (
        <Button
          id="itemButtonInOption"
          className={`${itemButton} ${itemButtonWhite}`}
          key={indexButton}
          onClick={() => {
            handleTransfer(index, indexButton);
          }}
        >
          { capitalize(option.name) }
        </Button>
      )
    }
  }

  return (
    <Grid container spacing={2}>
      {

        props.data.map((s, index) => {

          const curBlockButtonsNum =
          (typeof props.data[index].childrenData === "undefined")
            ? 1
            : props.data[index + 0].childrenData.length;

          const nextBlockButtonsNum =
          (typeof props.data[index].childrenData === "undefined")
            ? 1
            : props.data[index + 0].childrenData.length;

          if (threeInRowIndex === 4) {
            threeInRowIndex = -1;
          }
          if ((threeInRowIndex === -1) && (index <= props.data.length - 3)) {
            if (
              ((typeof props.data[index].childrenData == "undefined") || (props.data[index].childrenData.length < 3))
            && ((typeof props.data[index + 1].childrenData == "undefined") || (props.data[index + 1].childrenData.length < 3))
            && ((typeof props.data[index + 2].childrenData == "undefined") || (props.data[index + 2].childrenData.length < 3))
            ){
              threeInRowIndex = 0;
            }
          }
          if (threeInRowIndex > -1) {
            threeInRowIndex += 1;
          }

          // checking if 3-in-a-row condition works for the next 3 blocks
          if ((threeInRowIndex === -1) && (index <= props.data.length - 4)) {
            if (
              ((typeof props.data[index + 1].childrenData == "undefined") || (props.data[index + 1].childrenData.length < 3))
                && ((typeof props.data[index + 2].childrenData == "undefined") || (props.data[index + 2].childrenData.length < 3))
                && ((typeof props.data[index + 3].childrenData == "undefined") || (props.data[index + 3].childrenData.length < 3))
            ) {
              nextStartsThreeInRow  = true;
            }
          } else {
            nextStartsThreeInRow  = false;
          }

          // identifying the width of the current block
          let curBlockWidth = 0;
          if (curBlockButtonsNum > 3) {
            curBlockWidth = 12;
            lastRowClosed = true;
          } else{

            if ((threeInRowIndex >= 0) && (threeInRowIndex <= 3)) {
              curBlockWidth = 4;
              if (threeInRowIndex === 3) {
                lastRowClosed = true;
              }
            } else {

              if ((nextStartsThreeInRow) || (nextBlockButtonsNum > 3)) {

                if (lastRowClosed === false) {
                  curBlockWidth = 6;
                  lastRowClosed = true;
                } else{
                  curBlockWidth = 12;
                  lastRowClosed = true;
                }

              } else{

                if (lastRowClosed === false) {
                  curBlockWidth = 6;
                  lastRowClosed = true;
                } else{
                  curBlockWidth = 6;
                  lastRowClosed = false;
                }

              }

            }

          }

          return (
            <Grid
              key={`naviBlock${index}`}
              className={classes.menuBlock}
              item
              xs={curBlockWidth}
            >
              <Container className={classes.buttonContainer}>
                <Grid className={classes.buttonHeader} container>
                  <Grid item xs={12} className={classes.headerRow}>
                    <Box className={classes.headerBoxIcon}>
                      <a
                        id="headerBoxIcon"
                        href={getUrlPath({comp: s, childComp: null})}
                        onClick={()=>{
                          forwardToUrl({comp: s, childComp: null});
                          return false;
                        }}
                      >
                        {renderBlockIcon(s.name)}
                      </a>
                    </Box>
                    <Box className={classes.headerBoxTitle}>
                      <Typography
                        id="headerBoxTitle"
                        className={classes.headerBoxTitleText}
                        onClick={()=>{
                          forwardToUrl({comp: s, childComp: null})
                        }}
                      >
                        {s.name}
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              </Container>
              <Container className={classes.descriptionContainer}>
                <Typography className={classes.descriptionContainerTypography}>{role ? getStepLabel(s.description, role) : s.description}</Typography>
                <Box className={classes.descriptionContainerBox}>
                  {
                    (s.childrenData && (s.childrenData.length > 0)) ? (
                      s.childrenData.map((option, indexButton) => (
                        renderPossibleActions(classes, indexButton, index, option)

                      )))
                      : (
                        <Button
                          id="itemButton"
                          className={`${classes.itemButton} ${classes.itemButtonWhite}`}
                          onClick={() => forwardToUrl({comp: s, childComp: null})}
                        >
                          Manage
                        </Button>
                      )
                  }
                </Box>
              </Container>
            </Grid>
          )
        })
      }
    </Grid>
  );
}

export default withRouter(NaviButton);
