import { makeStyles } from '@material-ui/core';
import Colors from '../../theme/Colors';

const styles = theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    position: 'fixed',
    bottom: 0,
    zIndex: 3
  },
  barContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: Colors.subtleBlue,
    paddingLeft: 56,
    paddingRight: 56,
    boxSizing: 'border-box',
    height: 40
  },
  leftContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  text: {
    // fontWeight: fontMedium
    marginLeft: 16
  }
});

export default makeStyles(styles);
